import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, Menu, MenuItem, TextField, Typography } from '@mui/material';
import { Item } from '..';
import { useCategoryStore } from '../../store/useCategoryStore';
import { UseConsultarCategorias } from '../../category';
import { FormButtonsGroup } from './FormButtonsGroup';
import { VariancesTableOrAttributeMapList } from './VariancesTableOrAttributeMapList';

interface ItemFormProps {
  itemedit: Item;
  onClose: () => void;
  onSave: (item: Item) => void;
  onDelete: (id: string) => void;
  variances: any[]; // Asegúrate de que el tipo sea correcto
}

export const ItemForm: React.FC<ItemFormProps> = ({ itemedit, onClose, onSave, onDelete, variances }) => {
  const [attributeMap, setAttributeMap] = useState<Record<string, string[]>>({});
  const { categories, setCategories } = useCategoryStore(state => state);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [categoria, setCategoria] = useState(itemedit.categoria);

  const fetchCategories = async () => {
    try {
      const resp = await UseConsultarCategorias();
      setCategories(resp);
    } catch (error) {
      alert('Error al consultar las categorias' + error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    setItem(itemedit); 
  }, [itemedit]);

  const [item, setItem] = useState<Item>({
    id: itemedit.id,
    referencia: itemedit.referencia,
    nombre: itemedit.nombre,
    imagenurl: itemedit.imagenurl,
    categoria: itemedit.categoria,
    valorunitario: itemedit.valorunitario,
    precioventamayorista: itemedit.precioventamayorista,
    precioventaminimo: itemedit.precioventaminimo,
    precioventadistribuidor: itemedit.precioventadistribuidor,
    visible: false,
    selected: false,
    attributeMap: itemedit.attributeMap,
  });

  const updateAttributeMap = (
    variable: string,
    valor: string,
    selected: boolean
  ): Record<string, string[]> => {
    const variableValues = attributeMap[variable];

    if (variableValues) {
      if (!variableValues.includes(valor)) {
        if (selected) {
          return {
            ...attributeMap,
            [variable]: [...variableValues, valor],
          };
        } else {
          return attributeMap;
        }
      } else {
        if (!selected) {
          const updatedValues = variableValues.filter((v) => v !== valor);
          return {
            ...attributeMap,
            [variable]: updatedValues,
          };
        } else {
          return attributeMap;
        }
      }
    } else {
      if (selected) {
        return {
          ...attributeMap,
          [variable]: [valor],
        };
      } else {
        return attributeMap;
      }
    }
  };

  const handleCheckboxClick = (variable: string, valor: string, selected: boolean) => {
    const updatedAttributeMap = updateAttributeMap(variable, valor, selected);
    setAttributeMap(updatedAttributeMap);
    setItem(prevItem => ({
      ...prevItem,
      attributeMap: updatedAttributeMap
    }));
  };

  const handleUpdateItem = (updatedItem: Item) => {
    setItem(updatedItem);
  }

  const handleSave = (): void => {
    onSave(item);
    onClose();
  };

  const handleDelete = (): void => {
    onDelete(item.id);
    onClose();
  };

  const handleCancel = (): void => {
    onClose();
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (categoriaSeleccionada: string) => {
    setCategoria(categoriaSeleccionada);
    setItem({ ...item, categoria: categoriaSeleccionada });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box border={1} borderColor="black" style={{ borderStyle: 'solid' }} p="20px">

      <h2 id="modal-title">Nuevo Item -{itemedit?.id}-</h2>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nombre"
            fullWidth
            margin="normal"
            value={item.nombre}
            onChange={(e) => setItem({ ...item, nombre: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Imagen URL"
            fullWidth
            margin="normal"
            value={item.imagenurl}
            onChange={(e) => setItem({ ...item, imagenurl: e.target.value })}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Referencia"
            fullWidth
            margin="normal"
            value={item.referencia}
            onChange={(e) => setItem({ ...item, referencia: e.target.value })}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Valor unitario"
            fullWidth
            margin="normal"
            value={item.valorunitario}
            onChange={(e) =>
              setItem({
                ...item,
                valorunitario: parseFloat(e.target.value) || 0,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Precio venta mayorista"
            fullWidth
            margin="normal"
            value={item.precioventamayorista}
            onChange={(e) =>
              setItem({
                ...item,
                precioventamayorista: parseFloat(e.target.value) || 0,
              })
            }
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Precio venta minimo"
            fullWidth
            margin="normal"
            value={item.precioventaminimo}
            onChange={(e) =>
              setItem({
                ...item,
                precioventaminimo: parseFloat(e.target.value) || 0,
              })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Precio venta minorista"
            fullWidth
            margin="normal"
            value={item.precioventadistribuidor}
            onChange={(e) =>
              setItem({
                ...item,
                precioventadistribuidor: parseFloat(e.target.value) || 0,
              })
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl>
          <Typography variant="subtitle1" gutterBottom>
            Categoría
          </Typography>
            <Button
              aria-controls="categoria-menu"
              aria-haspopup="true"
              onClick={handleClickListItem}
            >
              {item.categoria || 'Selecciona una categoría'}
            </Button>
            <Menu
              id="categoria-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {categories.map((category) => (
                <MenuItem
                  key={category.id}
                  selected={category.nombre === categoria}
                  onClick={() => handleMenuItemClick(category.nombre)}
                >
                  {category.nombre}
                </MenuItem>
              ))}
            </Menu>
          </FormControl>
        </Grid>

        <VariancesTableOrAttributeMapList
          item={item}
          variances={variances}
          attributeMap={attributeMap}
          handleCheckboxClick={handleCheckboxClick}
          handleUpdateItem={handleUpdateItem}
        />

      </Grid>
      <FormButtonsGroup
        handleCancel={handleCancel}
        handleSave={handleSave}
        handleDelete={handleDelete}
      />
    </Box>
  );
};
