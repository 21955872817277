import React, { useEffect, useState } from 'react';
import { Box } from "@mui/material";
import { gsap } from 'gsap';
import { Item  } from '..';

import { UpdateItemFirebase } from '../infraestructure/updateItemFirebase';

import { ConsultarVariances } from '../../varianzas/application/consultarVariances';
import { useItemStore } from '../../store/useItemStore';
import { UseConsultarItems } from '../application/UseConsultarItems';
import { ItemsDataGrid } from '../components/ItemsDataGrid';
import { CreateItemButton } from '../components/CreateItemButton';
import { ItemForm } from '../components/ItemForm';
import { deleteItemFirebase } from '../infraestructure/deleteItemFirebase';

export const ItemsPage = (): JSX.Element => {
  const [showForm, setShowForm] = useState(false);

//  const [modalOpen, setModalOpen] = useState(false);

  const [item, setItem] = useState<Item>({
    id: '',
    referencia: '',
    nombre: 'string',
    categoria: '',
    imagenurl: '',
    valorunitario: 0,
    precioventamayorista: 0,
    precioventaminimo: 0,
    precioventadistribuidor: 0,
    visible: false,
    selected: false,
    attributeMap: {},
  });

  const {items, setItems }  = useItemStore(state => state);

  const queryVariances = ConsultarVariances();

  const handleEditButtonClick = (item: Item) => {
    setItem(item);
    setShowForm(true);
    animarCaja();
  };

  const handleNewButtonClick = () => {
    setShowForm(true);
    animarCaja();
    setItem({
      id: '',
      referencia: '',
      nombre: '',
      imagenurl: '',
      categoria: '',
      valorunitario: 0,
      precioventamayorista: 0,
      precioventaminimo: 0,
      precioventadistribuidor: 0,
      visible: false,
      selected: false,
      attributeMap: {},
    });
  };

  const fetchItems = async () => {
    try {
      const resp = await UseConsultarItems();
      setItems(resp);
    } catch (error) {
      alert('Error al consultar las categorias' + error);
    }
  };

  const animarCaja = () => {
    gsap.from(".itemsform", { x: 1000, duration: 1 })
  }

  useEffect(() => {
    fetchItems();
  }, []);

  const handleSave = async (editedItem: Item) => {
    try {
      await UpdateItemFirebase(editedItem);
      await fetchItems();
    } catch (error) {
      console.error('Error al actualizar el item: ', error);
      alert('Error al actualizar el item: ' + error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteItemFirebase(id);
      await fetchItems();
    } catch (error) {
      console.error('Error al actualizar el item: ', error);
      alert('Error al actualizar el item: ' + error);
    }
  };

  const itemForm = (
    <ItemForm
      itemedit={item}
      onClose={() => setShowForm(false)}
      onSave={ handleSave }
      onDelete={ handleDelete }
      variances={queryVariances.data ?? []}
    />
  )

  return (
    <Box m="20px">
      <Box display={showForm ? "none" : "block"}>
        <CreateItemButton onClick={handleNewButtonClick} />
      </Box>

      {!showForm && (
        <ItemsDataGrid items={items} 
          handleEditButtonClick={handleEditButtonClick} />
      )}

      <Box className="itemsform" display={showForm ? "block" : "none"}>
        {(itemForm)}
      </Box>
    </Box>
  );
};

