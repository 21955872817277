import React, { FC, useCallback, useMemo } from 'react';
import { Box, Typography, selectClasses } from '@mui/material';

import { Category } from '../../category';
import { useFacturaStore } from '../../store/useFacturaStore';

interface CategoriaProps {
  category: Category;
}

export const CategoryComponent: FC<CategoriaProps> = ({ category }) => {
  const { categoryNameSelected, setCategoryNameSelected } = useFacturaStore(state => state);

  const onSelectCategoria = useCallback(() => {
    setCategoryNameSelected(category.nombre);
  }, [category.nombre, setCategoryNameSelected]);

  const isSelected = useMemo(() => category.nombre === categoryNameSelected, [category.nombre, categoryNameSelected]);

  const boxStyle = useMemo(() => ({
    backgroundColor: 'white',
    mx: 0.3,
    cursor: 'pointer',
    py: 0.1,
    px: 0.8,
    borderRadius: 2,
    border: isSelected ? '2px solid #000' : `1px solid white`, 
    boxShadow: isSelected ? '0px 0px 10px rgba(0,0,0,0.5)' : 'none', 
    transform: isSelected ? 'scale(1.05)' : 'none', 
    transition: 'all 0.3s ease',
  }), [isSelected]);

  return (
    <Box
      key={category.id}
      sx={boxStyle}
      onClick={onSelectCategoria}
    >
      <Typography variant="h6">{category.nombre}</Typography>
    </Box>
  );
};
