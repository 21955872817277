import { Trm } from '..';
import { UpdateTrmFirebase } from '../infraestructure/updateTrmFirebase';

export const actualizarTrm = async (trm: Trm): Promise<boolean> => {
    try {
        return await UpdateTrmFirebase(trm);
    } catch (error) {
        console.error(error);
        return false;
    }
}
