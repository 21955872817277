import React, { useState } from 'react';
import { DataGrid, GridToolbar  } from "@mui/x-data-grid";
import { Button, Dialog, DialogContent, DialogTitle, useTheme  } from "@mui/material";
import { tokens } from "../../theme/tokens";
import { Header } from "../../components";
import { Box } from "@mui/material";

import { Variance, varianzaColumns } from '..';
import { ConsultarVariances } from '../application/consultarVariances';
import { LoadingComponent } from '../../ui/components';
import VariancesNewPage from './VariancesNewPage';
import { actualizarVariances } from '../application/actualizarVariances';
import VariancesEditPage from './VariancesEditPage';

export const VariancesPage = (): JSX.Element => {
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openEditForm, setOpenEditForm] = useState<boolean>(false);
  const [selectedVariance, setSelectedVariance] = useState<Variance | null>(null);

  const theme = useTheme();
  const varianceQuery = ConsultarVariances();

  const handleOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleCreateVariance = (newVariance: Variance) => {
    actualizarVariances(newVariance);
    handleCloseForm();
  };

  const handleEditButtonClick = (variance: Variance) => {
    setSelectedVariance(variance);
    setOpenEditForm(true);
  };

  if (varianceQuery.isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Box m="20px">
      <Header title="Varianzas" subtitle="" />

      <Button variant="contained" onClick={handleOpenForm}>
        Nueva Varianza
      </Button>

      <Dialog open={openForm} onClose={handleCloseForm}>
        <DialogTitle>Nueva Varianza</DialogTitle>
        <DialogContent>
          <VariancesNewPage onCreateVariance={handleCreateVariance} />
        </DialogContent>
      </Dialog>

      <Dialog open={openEditForm} onClose={() => setOpenEditForm(false)}>
        <DialogTitle>Editar Varianza</DialogTitle>
        <DialogContent>
          {selectedVariance && (
            <VariancesEditPage variance={selectedVariance} onUpdateVariance={
              handleCreateVariance
            } />
          )}
        </DialogContent>
      </Dialog>

      <Box m="40px 0 0 0" height="70vh">
        <DataGrid
          rows={varianceQuery.data ?? []}
          columns={[
            ...varianzaColumns,
            {
              field: 'edit',
              headerName: 'Editar',
              sortable: false,
              width: 100,
              renderCell: (params) => (
                <Button
                  variant="contained"
                  onClick={() => handleEditButtonClick(params.row as Variance)}
                >
                  Editar
                </Button>
              ),
            },
          ]}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

