import { GridColDef } from "@mui/x-data-grid";

export const itemColumns: GridColDef[] = [
    {
        field: "nombre",
        headerName: "Nombre",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "referencia",
        headerName: "Referencia",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "imagenurl",
        headerName: "Imagen Url",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "valorunitario",
        headerName: "Valor unitario",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "precioventamayorista",
        headerName: "Precio mayorista",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "precioventaminimo",
        headerName: "Precio minimo",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "precioventadistribuidor",
        headerName: "Precio distribuidor",
        type: "number",
        headerAlign: "left",
        align: "left",
    },
];
