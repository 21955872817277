import React, { FC, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, IconButton, TextField } from '@mui/material';
import { InventoryBalance } from '../domain/InventoyBalance';
import { UseGenerateInventoryBalancesreport } from '../../document/application/UseGenerateInventoryBalancesreport';
import { UseSearchFacturasByStateAndDocumentType } from '../../document/application/UseSearchFacturasByStateAndDocumentType';
import { DocumentType, Estado, Factura } from '../../document/domain/factura.interface';


interface InventoryReportTableProps { }

const InventoryReportTable: FC<InventoryReportTableProps> = (props) => {
  const [inventoryBalances, setInventoryBalances] = useState<InventoryBalance[]>([]);
  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const [inventoryDates, setInventoryDates] = useState<number[]>([]);
  const [inventarios, setInventarios] = useState<Factura[]>([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {

        const inventarios = await UseSearchFacturasByStateAndDocumentType(Estado.FINALIZADA, DocumentType.INVENTARIO);
        const dates = inventarios.map((factura: any) => new Date(factura.fecha).getTime());
        setInventoryDates(dates);
        setInventarios(inventarios);

        if (dates.length > 0) {
          setSelectedDate(dates[dates.length -1]);
          const { balances } = await UseGenerateInventoryBalancesreport(dates[0], inventarios[0]);

          setInventoryBalances(balances);
        }

      } catch (error) {
        console.error('Error al obtener el informe de inventario:', error);
      }
    };

    fetchData();
  }, []);

  const handleDateChange = async (event: SelectChangeEvent<number>) => {
    const selectedDate = event.target.value as number;
    setSelectedDate(selectedDate);

    const selectedInventory = inventarios.find((factura: any) => new Date(factura.fecha).getTime() === selectedDate);
    if (selectedInventory) {
      const { balances } = await UseGenerateInventoryBalancesreport(selectedDate, selectedInventory);
      setInventoryBalances(balances);
    }
  };

  const handleRowClick = (id: string) => {
    setSelectedInvoiceId(selectedInvoiceId === id ? null : id);

    // Aquí puedes abrir el detalle correspondiente a la fila con el id proporcionado
    console.log('Fila clickeada:', id);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredBalances = inventoryBalances.filter(balance => {
    return (
      balance.referencia.toLowerCase().includes(searchTerm.toLowerCase()) ||
      balance.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <>
      <FormControl>
        <InputLabel id="fecha-inventario-label">Fecha de inventario</InputLabel>
        <Select
          labelId="fecha-inventario-label"
          id="fecha-inventario-select"
          value={selectedDate || ''}
          onChange={handleDateChange}
        >
          {inventoryDates.map(date => (
            <MenuItem key={date} value={date}>{new Date(date).toLocaleDateString()}</MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        id="search"
        label="Buscar Referencia/Nombre"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      {selectedDate && (
        <p>Fecha del inventario seleccionada: {new Date(selectedDate).toLocaleDateString()}</p>
      )}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Referencia</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Inventario Inicial</TableCell>
              <TableCell>Costo</TableCell>
              <TableCell>Ventas</TableCell>
              <TableCell>Saldo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredBalances.map((balance) => (
              <React.Fragment key={balance.id}>
                <TableRow onClick={() => handleRowClick(balance.id)}>
                  <TableCell>{balance.referencia}</TableCell>
                  <TableCell>{balance.nombre}</TableCell>
                  <TableCell>{balance.inventarioinicial}</TableCell>
                  <TableCell>{balance.ingresos}</TableCell>
                  <TableCell>{balance.ventas}</TableCell>
                  <TableCell>{balance.inventarioinicial + balance.ingresos - balance.ventas}</TableCell>
                </TableRow>
                {selectedInvoiceId === balance.id && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            {balance.attributes.map((attribute, attributeIndex) => (
                              <TableRow key={`${attributeIndex}-${attributeIndex}`}>
                                {/* Mostrar atributos anidados */}
                                {Object.entries(attribute).map(([key, value]) => (
                                  <React.Fragment key={key}>
                                    {typeof value === 'object' ? (
                                      Object.entries(value).map(([nestedKey, nestedValue]) => (
                                        <TableCell key={nestedKey}>{`${nestedKey}: ${nestedValue}`}</TableCell>
                                      ))
                                    ) : (
                                      <TableCell>{`${key}: ${value}`}</TableCell>
                                    )}
                                  </React.Fragment>
                                ))}

                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                      </TableContainer>
                    </TableCell>
                  </TableRow>

                )}
              </React.Fragment>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
    </>
  );
};

export default InventoryReportTable;
