import { collection, doc, setDoc, updateDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { Adviser } from '../domain/adviser.interface';

export const updateAdviserFirebase = async (adviser: Adviser) => {
    try {
        let adviserDocRef;

        const adviserData = {
            dni: adviser.dni,
            name: adviser.name
        };

        if (adviser.id) {
            adviserDocRef = doc(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/advisers`, adviser.id);
            await updateDoc(adviserDocRef, adviserData);
        } else {
            adviserDocRef = doc(collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/advisers`));
            await setDoc(adviserDocRef, adviserData);
            adviser.id = adviserDocRef.id;
        }
    } catch (error) {
        console.error(error);
    }
}
