import React, { FC, useEffect, useState } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { IconButton } from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { DocumentType, Estado, Factura } from '../../document/domain/factura.interface';
import { getDate } from '../../helpers/getDate';
import InvoiceDetailRow from '../components/InvoiceDetailRow';
import { formatCurrency } from '../../helpers/formatCurrency';
import { Header } from '../../components';
import { UseSearchFacturasByStateAndDocumentType } from '../../document/application/UseSearchFacturasByStateAndDocumentType';

interface InventaroyListProps {

}

const InventoryList: FC<InventaroyListProps> = (props): JSX.Element => {
  const [facturas, setFacturas] = useState<Factura[]>([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | undefined>(undefined);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await UseSearchFacturasByStateAndDocumentType(Estado.FINALIZADA, DocumentType.INVENTARIO);
        setFacturas(result);
      } catch (error) {
        console.error('Error al obtener el informe de inventario:', error);
      }
    };

    fetchData();
  }, []);


  const handleInvoiceClick = (id: string) => {
    setSelectedInvoiceId(selectedInvoiceId === id ? undefined : id);
};

  return (
    <Box m="20px">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center', // Alinea verticalmente los elementos
          justifyContent: 'space-between', // Separa el Header y el botón a extremos opuestos
        }}
      >
        <Header title="FACTURAS" subtitle="" />
      </Box>

      <Box
        m="0 0 0 0"
        height="75vh"
        // custom css for material ui
      >
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Consecutivo</TableCell>
                <TableCell>Fecha</TableCell>
                <TableCell>Productos</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {facturas.map((invoice) => (
                <React.Fragment key={invoice.id}>
                <TableRow>
                    <TableCell>{invoice.consecutivo}</TableCell>
                    <TableCell>{getDate(invoice.fecha)}</TableCell>

                    <TableCell>
                      <IconButton onClick={() => handleInvoiceClick(invoice.id ?? '')}>
                        {selectedInvoiceId === invoice.id ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                      {invoice.detalles?.length}
                    </TableCell>
                    <TableCell>{invoice.estado}</TableCell>
                    <TableCell>{formatCurrency(invoice.total)}</TableCell>
                </TableRow>
                {selectedInvoiceId === invoice.id && <InvoiceDetailRow factura={invoice} />}
                </React.Fragment>            
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default InventoryList;
