import React, { useState } from 'react';
import { InvoiceModal } from './invoice/InvoiceModal';
import { Button } from '@mui/material';
import { useFacturaStore } from '../../store/useFacturaStore';

const PrintButton: React.FC = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { factura } = useFacturaStore(state => state);

  return (
    <div>
      <Button variant="contained"
            onClick={() => setModalOpen(true)}>Imprimir</Button>

      {modalOpen && 
          <InvoiceModal 
              open={modalOpen}
              factura={factura}
              onClose={() => setModalOpen(false)} 
          />}

    </div>
  );
};

export default PrintButton;
