import React, {useEffect} from 'react';
import { DataGrid, GridToolbar  } from "@mui/x-data-grid";
import { useTheme  } from "@mui/material";
import { tokens } from "../../theme/tokens";
import { Header } from "../../components";
import { Box } from "@mui/material";

import { categoryColumns, UseConsultarCategorias } from '..';
import { useCategoryStore } from '../../store/useCategoryStore';

export const CategoriesPage = (): JSX.Element => {

  const theme = useTheme();

  const colors = tokens(theme.palette.mode);

  const {categories, setCategories }  = useCategoryStore(state => state);

  const fetchCategories = async () => {
    try {
      const resp = await UseConsultarCategorias();
      setCategories(resp);
    } catch (error) {
      alert('Error al consultar las categorias' + error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Box m="20px">

      <Header title="Categorias" subtitle="" />

      <Box
        m="40px 0 0 0"
        height="65vh"
        // custom css for material ui
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          // 🟧🟧🟧 data filter tool-bar present here 🟧🟧🟧
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={categories}
          columns={categoryColumns}
          components={{ Toolbar: GridToolbar }}
        />

      </Box>
    </Box>
  );
};

