import { collection, doc, setDoc, updateDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { Variance } from '../domain/variance.interface';

export const CreateVarianceFirebase = async (variance: Variance) => {
    try {
        const varianceData = {
            name: variance.name,
            value: variance.value,
        };

        if (variance.id) {
            const varianceDocRef = doc(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/variances`, variance.id);
            await updateDoc(varianceDocRef, varianceData);
        } else {
            const varianceCollectionRef = collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/variances`);
            const newVarianceDocRef = doc(varianceCollectionRef);

            await setDoc(newVarianceDocRef, varianceData);

            variance.id = newVarianceDocRef.id; // Asigna el ID generado al objeto Variance
        }

        return variance.id; // Devuelve el ID de la varianza creada o actualizada
    } catch (error) {
        console.error(error);
        throw new Error('Error al crear o actualizar la varianza en Firestore');
    }
}
