import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Box, ListItemText, TextField, TableCell, TableRow } from '@mui/material';
import { TurnedInNot } from '@mui/icons-material';
import { ImageGallery } from '../ImageGallery';
import { Detalle } from '../../domain/factura.interface';
import { useFacturaStore } from '../../../store/useFacturaStore';

import { formatNumber } from '../../../helpers/formatNumber';
import { UseActualizarFactura } from '../../application/UseUpdateFactura';
import { UseActualizarCantidadFactura } from '../../application/useActualizarCantidadFactura';
import { UseActualizarValorUnitarioFactura } from '../../application/useActualizarValorUnitario';

interface ItemSelectedProps {
  detalle: Detalle;
}

const ItemSelected: React.FC<ItemSelectedProps> = ({ detalle }) => {
  const [cantidad, setCantidad] = useState(detalle.cantidad + '');
  const [valorUnitario, setValorUnitario] = useState(detalle.valorunitario);
  const { factura, setFactura, updateFacturaPendiente,
    getDocumentTypeByFacturaType } = useFacturaStore(state => state);

  const amountEditable = getDocumentTypeByFacturaType()?.amounteditable;

  useEffect(() => {
    setCantidad(detalle.cantidad + '');
  }, [detalle.cantidad]);


  const onAdd = () => {
    const fac = UseActualizarCantidadFactura(factura, detalle.id, '+');
    try {
      UseActualizarFactura(fac);
    } catch (error) {
      alert(error);
      return;
    }
    setFactura(fac);
    updateFacturaPendiente(fac);
  };

  const onSub = () => {
    const fac = UseActualizarCantidadFactura(factura, detalle.id, '-');
    try {
      UseActualizarFactura(fac);
    } catch (error) {
      alert(error);
      return;
    }
    setFactura(fac);
    updateFacturaPendiente(fac);
  };

  const handleInputCantidadChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nuevoValor = event.target.value.trim();
    let nuevaCantidad = 0;
    const numero = parseInt(nuevoValor);

    if (!(!isNaN(numero) && numero >= 0)) {
      nuevaCantidad = 0
    } else {
      nuevaCantidad = numero;
    }

    if (!isNaN(nuevaCantidad) && nuevaCantidad <= 999) {
      if (nuevaCantidad == 0) {
        setCantidad('');  
      } else {
        setCantidad(nuevaCantidad + '');
      }

      const fac = UseActualizarCantidadFactura(factura, detalle.id, nuevaCantidad);
      try {
        UseActualizarFactura(fac);
      } catch (error) {
        alert(error);
        return;
      }
      setFactura(fac);
      updateFacturaPendiente(fac);
    } else if (event.target.value === '') {
      // Si el valor es vacío, establecemos la cantidad como 0
      setCantidad('');
    }
  };
  
  
  const handleInputValorUnitarioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nuevoValorUnitario = parseInt(event.target.value);
    setValorUnitario(nuevoValorUnitario);
    const fac = UseActualizarValorUnitarioFactura(factura, detalle.id, nuevoValorUnitario);
    try {
      UseActualizarFactura(fac);
    } catch (error) {
      alert(error);
      return;
    }
    setFactura(fac);
    updateFacturaPendiente(fac);
  };
/*
  const handleAgregarVariante = () => {
    const fac = UseAddVarianceFactura(factura, detalle, 1);
    setFactura(fac);
  }
*/
  return (
    <>
      {getDocumentTypeByFacturaType()?.amounteditable ? (
        <>
          <TableRow
            key={detalle.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row" sx={{ padding: 0}}>
              {detalle.nombre}
              <br />
              {detalle.attributeSelection?.map((attribute) => (
                <span key={attribute.key}>
                  <span key={attribute.key}>
                  {attribute.key}: <strong> {attribute.value}</strong>&nbsp;&nbsp;
                  </span>
                </span>
              ))}
            </TableCell>
            
            <TableCell align="right" style={{ padding: 0 }}>
              <TextField 
                type="number"
                value={cantidad}
                onChange={handleInputCantidadChange}
                style={{ width: '80px' }}
                inputProps={{ max: 999 }}
              />
            </TableCell>

            <TableCell align="right" style={{ padding: 0 }}>
              {
                amountEditable ? (
                  <TextField 
                    type="number"
                    value={valorUnitario}
                    onChange={handleInputValorUnitarioChange}
                  />
                ) : <> 
                      {detalle.valorunitario} 
                    </>
              }
              </TableCell>
            <TableCell align="right">{formatNumber(detalle.total)}</TableCell>
            <TableCell align="right">

            </TableCell>
          </TableRow>
          { /* 
          
        {getDocumentTypeByFacturaType()?.includevariaces ? (
          <TableRow
            key={'x' + detalle.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <Button
                variant="text"
                startIcon={<AddCircle />}
                onClick={handleAgregarVariante}
              >
                Agregar variante
              </Button>

            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">

            </TableCell>
          </TableRow>
          ) : null}
        */}
        </>
      ) : (
        <Box
          key={detalle.iditem}
          sx={{
            border: '1px solid green',
            width: '150px',
            padding: '10px',
            margin: '5px',
            borderRadius: '15px',
            cursor: 'pointer',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 20,
              backgroundColor: 'green',
              color: 'white',
              padding: '5px 3px',
              borderRadius: '0px 0px 8px 8px',
            }}
          >
            <TurnedInNot />
          </Box>

          <Box
            sx={{
              textAlign: 'center',
            }}
          >
            <ImageGallery imagenurl={detalle.imagenurl} />

            <ButtonGroup variant="contained" aria-label="outlined primary button group">
              <Button
                sx={{ backgroundColor: 'green' }} // Cambia 'green' por el color que desees
                onClick={onSub}
              >
                -
              </Button>
              <Button
                sx={{ backgroundColor: 'green' }} // Cambia 'green' por el color que desees
                onClick={onAdd}
                disabled={false}
              >
                +
              </Button>
            </ButtonGroup>

            <ListItemText sx={{ my: 0 }} primary={detalle.nombre + ': ' + detalle.cantidad} />
            <ListItemText sx={{ my: 0 }} primary={formatNumber(detalle.total)} />
          </Box>
        </Box>

      )}
    </>
  );
};

export default ItemSelected;
