import { DocumentSnapshot } from 'firebase/firestore/lite';
import { Estado, Estadoorden, Factura } from '../domain/factura.interface';

export const processDocument = (doc: DocumentSnapshot): Factura => {
  const data = doc.data();

  return {
    id: doc.id,
    tipo: data?.tipo || '',
    fecha: data?.fecha || 0,
    consecutivo: data?.consecutivo || 0,
    usuarioid: data?.usuarioid || '',
    estado: data?.estado || Estado.ENPROCESO,
    estadoorden: data?.estadoorden || Estadoorden.PENDIENTE,
    detalles: data?.detalles || [],
    total: data?.total || 0,
    totaldolar: data?.totaldolar || 0,
    idbodega: data?.idbodega || '',
    nombrebodega: data?.nombrebodega || '',
    facturaendolares: data?.facturaendolares || false,
    valordolar: data?.valordolar || 0,
    tercero: data?.tercero || null,
    adviserinformation : data?.adviserinformation || null,
    deliveryinformation : data?.deliveryinformation || null,
    color: data?.color || '',
    weight: data?.weight || 0
  };
};
