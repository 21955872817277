import { create } from 'zustand'
import { Item } from '../items';

type State = {
    items: Item[];
}

type Actions = {
    setItems: (items: Item[]) => void;
    clearStore: () => void;
}

export const useItemStore = create<State & Actions>((set) => ({

    items: [],

    setItems: (items: Item[]) => set(state => ({
        items: items
    })),
    clearStore: () => {
        set({}, true)
    }
}))