import React from 'react';
import { FC } from 'react'
import { Box } from '@mui/system'

interface Props  extends React.PropsWithChildren<{

}> {}

export const InventarioLayout: FC<Props> = ({ children }) => {
  return (
    <>
    <Box mx="20px"
        height={"calc(100% - 69px)"}
        position="relative">
      { children }
    </Box>
    </>
  )
}
