
export interface DocumentParameter {
  type: string;
  includecustomer: boolean;
  includecolor: boolean;
  includesupplier: boolean;
  valuefrom: string;
  amounteditable: boolean;
  includevariaces: boolean;
  itemAlign: 'HORIZONTAL' | 'VERTICAL';
  includebillable: boolean;
  texttoregister: string
  includeChange?: boolean;
}

export const documentParameters:DocumentParameter[]  = [
  { type: "INVENTARIO",
    includecustomer: false,
    includecolor: false,
    includesupplier: false,
    valuefrom: "",
    amounteditable: true,
    includevariaces: true,
    itemAlign: 'HORIZONTAL',
    includebillable: false,
    texttoregister: 'Registrar ',
    includeChange: false
  },
  { type: "VENTAMOSTRADOR",
    includecustomer: false,
    includecolor: true,
    includesupplier: false,
    valuefrom: "",
    amounteditable: false,
    includevariaces: false,
    itemAlign: 'VERTICAL',
    includebillable: false,
    texttoregister: 'Registrar',
    includeChange: true
  },
  { type: "VENTACLIENTE",
    includecustomer: true,
    includecolor: false,
    includesupplier: false,
    valuefrom: "",
    amounteditable: true,
    includevariaces: true,
    itemAlign: 'HORIZONTAL',
    includebillable: true,
    texttoregister: 'Terminar venta',
    includeChange: false
  },
  { type: "INGRESO",
    includecustomer: false,
    includecolor: false,
    includesupplier: true,
    valuefrom: "",
    amounteditable: true,
    includevariaces: false,
    itemAlign: 'HORIZONTAL',
    includebillable: false,
    texttoregister: 'Registrar',
    includeChange: false
  },
];
