import React, { useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../theme/tokens";
import { Header } from "../../components";
import { Box } from "@mui/material";

import { AdviserModal } from '../components/adviserModal';
import { ConsultarAdviser } from '../application/consultarAdviser';

import { GridColDef } from "@mui/x-data-grid";
import { EditOutlined } from '@mui/icons-material';
import { Adviser } from '../domain/adviser.interface';
import { actualizarAdviser } from '../application/actualizarAdviser';

export const AdviserPage = (): JSX.Element => {
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);
  const [ adviser, setTercero] = useState<Adviser>({
    dni: '',
    name: ''
  });

  const colors = tokens(theme.palette.mode);

  const queryTerceros = ConsultarAdviser();

  const handleEditButtonClick = ( adviser: Adviser) => {
    setTercero( adviser);
    setModalOpen(true);
  };

  const adviserColumns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
        field: "dni",
        headerName: "DNI",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        cellClassName: "name-column--cell",
    },

    {
        field: 'edit',
        headerName: 'Editar',
        width: 100,
        renderCell: (params) => (
          <IconButton type="button" sx={{ p: 1 }}
          onClick={() => handleEditButtonClick(params.row)}
          >
          <EditOutlined />
        </IconButton>
        ),
    },
];

  return (
    <Box m="20px">

      <Header title="Asesores" subtitle="" />

      <Box
        m="40px 0 0 0"
        height="65vh"
        // custom css for material ui
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          // 🟧🟧🟧 data filter tool-bar present here 🟧🟧🟧
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button variant="contained"
            onClick={() => setModalOpen(true)}>Crear asesor</Button>
        </Box>

        <DataGrid
          rows={(queryTerceros.data ?? [])}
          columns={adviserColumns}
          components={{ Toolbar: GridToolbar }}
        />

        {modalOpen &&
          <AdviserModal
            open={modalOpen}
             adviser={ adviser}
            onClose={() => setModalOpen(false)}
            onSave={(item: Adviser) => {
              actualizarAdviser(item);
            }}
          />}
      </Box>
    </Box>
  );
};
