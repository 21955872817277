import { collection, doc, setDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { addNewEmptyFactura, setActiveFactura } from './';
import { savingNewFactura, 
         setFacturas, 
         setSaving, 
         updateFactura,
         setitemsSelected } from './facturaSlice';
import { loadFacturas } from '../../helpers/loadFacturas';
import { Estado } from '../../document/domain/factura.interface';

export const startNewFactura = () => {
    return async( dispatch, getState ) => {

        dispatch( savingNewFactura() );

        const { uid, idbodega, nombrebodega } = getState().auth;

        const newFactura = {
            tipo: DocumentType.VENTAMOSTRADOR,
            fecha: new Date().getTime(),
            consecutivo: 0,
            usuarioid: uid,
            estado: Estado.PENDIENTE,
            detalles: [],
            total: 0,
            idbodega: idbodega,
            nombrebodega: nombrebodega
        }

        const newDoc = doc( collection( FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/movimientos`) );
        await setDoc( newDoc, newFactura );

        newFactura.id = newDoc.id;  

        dispatch( addNewEmptyFactura( newFactura ) );
        dispatch( setActiveFactura( newFactura ) );
        dispatch(setitemsSelected([]))
    }
}

export const startLoadingFacturas = () => {
    return async( dispatch, getState ) => {
        
        const { uid } = getState().auth;
        if ( !uid ) throw new Error('El UID del usuario no existe');

        const facturas = await loadFacturas( 'PENDIENTE' );

        dispatch( setFacturas( facturas ) );
    }
}

export const startSaveFactura = () => {
    return async( dispatch, getState ) => {
        dispatch( setSaving() );

        const { active:factura } = getState().factura;

        const facturaToFireStore = { ...factura };
        delete facturaToFireStore.id;
    
        const docRef = doc( FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/movimientos/${ factura.id }` );
        await setDoc( docRef, facturaToFireStore, { merge: true });

        dispatch( updateFactura( factura ) );
    }
}