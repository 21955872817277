import React from 'react';
import { Button } from '@mui/material';

interface ButtonProps {
  onClick: () => void; // Tipo para la función onClick
}

export const DeleteButton: React.FC<ButtonProps> = ({ onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      backgroundColor: 'red',
      color: 'white',
      margin: '2px',
      '&:hover': {
        backgroundColor: 'darkred',
      },
    }}
  >
    Borrar
  </Button>
);