import { collection, doc, setDoc, updateDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { Trm } from '..';

export const UpdateTrmFirebase = async (trm: Trm): Promise<boolean> => {
    try {
        let itemDocRef;

        const itemData = {
            value: trm.value
        };

        if (trm.id) {
            itemDocRef = doc(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/trm`, trm.id);
            await updateDoc(itemDocRef, itemData);
        } else {
            itemDocRef = doc(collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/trm`));
            await setDoc(itemDocRef, itemData);
            trm.id = itemDocRef.id;
        }

        return true;

    } catch (error) {
        console.error(error);
        alert(error);
        return false; // Retorna false si ocurrió un error

    }
}
