import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Factura } from '../../domain/factura.interface';

const styles = StyleSheet.create({
    companyContainer: {
        marginTop: 10,

        justifyContent: 'flex-end',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
    },
});

type InvoiceClientToProps = {

};

const InvoiceCompany: React.FC<InvoiceClientToProps> = () => (
    <View style={styles.companyContainer}>
        <Text>Solo Pijamas Cali</Text>
        <Text>94447781-5</Text>
        <Text>Cali/Valle</Text>
        <Text>573052904395</Text>
    </View>
);

export default InvoiceCompany;
