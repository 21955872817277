export interface Color {
  color: string;
}
export const tilesColors: Color[] = [
    { color: "#e3f2fd" },
    { color: "#90caf9" },
    { color: "#42a5f5" },
    { color: "#f3e5f5" },
    { color: "#ce93d8" },
    { color: "#ab47bc" },
    { color: "#e57373" },
    { color: "#f44336" },
    { color: "#d32f2f" },
    { color: "#ffb74d" },
    { color: "#ffa726" },
    { color: "#f57c00" },
    { color: "#4fc3f7" },
    { color: "#29b6f6" },
    { color: "#0288d1" },
    { color: "#81c784" },
    { color: "#66bb6a" },
    { color: "#388e3c" },
  ];
  