import { api } from '../../api/registrarPagosOffLineApi';
import { loginWithEmailPassword, logoutFirebase, registerUserWithEmailPassword, singInWithGoogle } from '../../firebase/providers';
import { consultarUsuario } from '../../usuarios/application/consultarUsuario';
import { checkingCredentials, logout, login } from './';

export const checkingAuthentication = () => {
    return async( dispatch ) => {
        dispatch( checkingCredentials() );
    }
}

export const startGoogleSignIn = () => {
    return async( dispatch ) => {

        dispatch( checkingCredentials() );
        const result = await singInWithGoogle();
        if ( !result.ok ) return dispatch( logout( result.errorMessage ) );

        const usuario = await consultarUsuario(result.uid) ;

        dispatch( login( {...result, 
                          idbodega: usuario.bod.id,
                          nombrebodega: usuario.bod.nombre
                        } ))
    }
}

export const startCreatingUserWithEmailPassword = ({ email, password, displayName }) => {
    return async( dispatch ) => {
        dispatch( checkingCredentials() );

        const result = await registerUserWithEmailPassword({ email, password, displayName });
        if ( !result.ok ) return dispatch( logout( result.errorMessage ) );

        dispatch( login( result ))

    }
}

export const startLoginWithEmailPassword = ({ email, password }) => {
    return async( dispatch ) => {
        dispatch( checkingCredentials() );

        const result = await loginWithEmailPassword({ email, password });

        const usuario = await consultarUsuario(result.uid) ;

        if ( !result.ok ) return dispatch( logout( result ) );

        dispatch( login( {...result, 
            idbodega: usuario.bod.id,
            nombrebodega: usuario.bod.nombre
          } ));
    }
}

export const startLoginWithUsuarioPassword = ({ usuario, contrasena }) => {
    return async( dispatch ) => {
        let credenciales = {
            "username": usuario,
            "password": contrasena
        };
        dispatch( checkingCredentials() );
        try{
            const resp = await api.post('/api/auth/login', 
                credenciales);
            window.localStorage.setItem('token', resp.data.access_token);

            dispatch(login('resp:', resp))
        } catch (error) {
            let mensaje = '';
            if (error.response?.request?.status === 401) {
                mensaje = 'Error de autenticacion. Usuario o contraseña no son validos';
            } else{
                mensaje = 'Error in executeFetch. WS: Error: ' + error.message;
            }
            alert(mensaje);
            dispatch(logout({errorMessage: mensaje}))
        }
    }
}

export const startLogout = () => {
    return async( dispatch ) => {
        await logoutFirebase();
        window.localStorage.removeItem('token');
//        await removeItemLocalStore('token');
        dispatch( logout() );
    }
}
