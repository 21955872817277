import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace';
import InvoiceTableFooter from './InvoiceTableFooter';
import { Factura } from '../../domain/factura.interface';

const tableRowsCount = 8;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

type InvoiceProps = {
    factura: Factura;
};

const InvoiceItemsTable: React.FC<InvoiceProps> = ({ factura }) => (
    <View style={styles.tableContainer}>
        <InvoiceTableHeader mostrarDolares={ factura.facturaendolares } />
        <InvoiceTableRow detalles={ factura.detalles } mostrarDolares = {factura.facturaendolares} />
        <InvoiceTableBlankSpace rowsCount={tableRowsCount - factura.detalles.length} />
        <InvoiceTableFooter factura={ factura } />
    </View>
);

export default InvoiceItemsTable;
