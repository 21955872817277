import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginFirebasePage } from '../pages';

export const AuthRoutes = () => {
  return (
    <Routes>
        <Route path="/*" element={ <LoginFirebasePage /> } /> 

    </Routes>
  )
}


/*

        <Route path="login" element={ <LoginFirebasePage /> } />

        <Route path='/*' element={ <Navigate to="/auth/login" /> } />

    <Routes>
      {

        (status === 'authenticated')
           ? <Route path="/*" element={ <InventarioRoutes /> } />
          : <Route path="/auth/*" element={ <AuthRoutes /> } />
        }
        <Route path="/auth/*" element={ <AuthRoutes /> } />
        <Route path="/*" element={ <Navigate to="/auth" /> } />
  
      </Routes>

      */