import { collection, getDocs } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { Adviser } from '../domain/adviser.interface';

export const loadAdvisers = async (): Promise<Adviser[]> => {
  if (!process.env.REACT_APP_CLIENTE_ID) throw new Error('El UID del cliente no existe');

  const collectionRef = collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/advisers`);
  const docs = await getDocs(collectionRef);

  const advisers: Adviser[] = [];
  docs.forEach((doc) => {
    advisers.push(
      { id: doc.id,
        dni: doc.data().dni,
        name: doc.data().name,
      }
    );
  });
  
  return advisers;
};
