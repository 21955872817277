import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Factura } from '../../domain/factura.interface';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 10,
        marginLeft: 50,
        justifyContent: 'flex-end',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        paddingTop: 3,
        paddingBottom: 3
    },
    billTo: {
        flexDirection: 'row',
        marginTop: 10,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
        justifyContent: 'flex-end'
    },
});

type InvoiceClientToProps = {
    factura: Factura;
};

const InvoiceClientTo: React.FC<InvoiceClientToProps> = ({ factura }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>CLIENTE:</Text>
        <Text>Nombre { factura.tercero?.nombre } { factura.tercero?.apellido }</Text>
        <Text>Codigo { factura.tercero?.codigo }</Text>
        <Text>Pais/Ciudad { factura.tercero?.pais } / { factura.tercero?.ciudad }</Text>
        <Text>Teléfono { factura.tercero?.telefono }</Text>
    </View>
);

export default InvoiceClientTo;
