export const getDate = (date: number) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = newDate.toLocaleString('es-ES', { month: 'short' });
  const day = newDate.getDate();

  let formattedDate = `${day} ${month}`;
  if (year !== new Date().getFullYear()) {
    formattedDate += ` ${year}`;
  }

  return formattedDate;
}
