import React from 'react';
import { TableCell, TableRow, Table, TableHead, TableBody } from '@mui/material';
import { AttributeSelection, Detalle, DetalleItem, Factura } from '../../document/domain/factura.interface';
import { formatCurrency } from '../../helpers/formatCurrency';

interface FacturaDetailRowProps {
    factura: Factura;
}

const InvoiceDetailRow: React.FC<FacturaDetailRowProps> = ({ factura }) => {
    return (
        <TableRow>
            <TableCell colSpan={5}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Referencia</TableCell>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Cantidad</TableCell>
                            <TableCell>Valor Unitario</TableCell>
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {factura.detalles.map((detalle: Detalle) => (
                            <TableRow key={detalle.id}>
                                <TableCell>{detalle.referencia}</TableCell>
                                <TableCell>{detalle.nombre}</TableCell>
                                <TableCell>{detalle.cantidad}</TableCell>
                                <TableCell>{formatCurrency(detalle.valorunitario)}</TableCell>
                                <TableCell>{formatCurrency(detalle.total)}</TableCell>


                                {detalle.attributeSelection && (
                                    <TableCell>
                                        {detalle.attributeSelection.map((selection: AttributeSelection, index: number) => (
                                            <div key={index}>
                                                <strong>{selection.key}:</strong> {selection.value}
                                            </div>
                                        ))}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableCell>
        </TableRow>
    );
};

export default InvoiceDetailRow;
