import { Factura } from '../domain/factura.interface';
import { Item } from '../../items';
import { TipoTercero } from '../../terceros';

export function UseUnitPrice(factura: Factura, item: Item): number {
    switch (factura.tercero?.tipo) {
        case TipoTercero.CLIENTEMAYORISTA:
            return item.precioventamayorista;
        case TipoTercero.CLIENTEDISTRIBUIDOR:
            return item.precioventadistribuidor;
        default:
            return item.valorunitario;
    }
}
