import React, { FC } from 'react';

import { Box, Typography } from "@mui/material";
import { useCategoryStore } from '../../store/useCategoryStore';
import { Category } from '../../category';
import { CategoryComponent } from './CategoriaComponent';

export const FacturaCategories: FC = () => {
  const { categories } = useCategoryStore(state => state);
  const todas: Category = {id: '0', nombre: 'Todas'};

  const categoriasConTodas = [todas, ...categories];

  return (
    <Box
      width="calc(100% - 57px)"
      display="flex"
      justifyContent="start"
      zIndex={2}
      sx={{m: .4}}
    >
      <Box
        display="flex"
        overflow="auto"
      >
        {
           categoriasConTodas?.length > 0 ?
           categoriasConTodas.map(category => (
              <CategoryComponent key={category.nombre} category={category}/>
        
            )) :
            <Box sx={{margin: '20px 0px'}}>
                <Typography fontSize={ 20 } fontWeight='light' >No hay categorias disponibles</Typography>
            </Box>
        }
      </Box>
    </Box>
  )
}
