import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Factura } from '../../domain/factura.interface';
import { formatNumber } from '../../../helpers/formatNumber';

const borderColor = '#000000';
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    totalCell: {
        width: '14.2%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    headerCell: {
        width: '14.2%',
        textAlign: 'center',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
});


type InvoiceTableFooterProps = {
    factura: Factura;
};

const InvoiceTableFooter: React.FC<InvoiceTableFooterProps> = ({ factura }) => {
    const mostrarDolares = factura.facturaendolares;
    
    const cantidadTotal = factura.detalles.reduce((total, detalle) => total + detalle.cantidad, 0);

    return (
        <Fragment>
        <View style={styles.row}>
            <Text style={styles.totalCell}></Text>
            <Text style={styles.totalCell}>Total</Text>
            <Text style={styles.totalCell}>{cantidadTotal.toFixed(2)}</Text>
        
            <Text style={styles.totalCell}>Total</Text>
            <Text style={styles.totalCell}>{formatNumber(factura.total)}</Text>
            {mostrarDolares && (
                <>
                    <Text style={styles.totalCell}>Total dólares aproximadamente</Text>
                    <Text style={styles.totalCell}>{factura.totaldolar.toFixed(2)}</Text>
                </>
            )}
        </View>

        <View style={styles.row}>
            <Text style={styles.headerCell}>Peso KG</Text>
            <Text style={styles.headerCell}>Total COP</Text>
            <Text style={styles.headerCell}></Text>
            {mostrarDolares && (
                <>
                    <Text style={styles.headerCell}>Total dólares aproxima</Text>
                    <Text style={styles.totalCell}></Text>
                    <Text style={styles.totalCell}></Text>
                    <Text style={styles.totalCell}></Text>
                </>
            )}
        </View>

        <View style={styles.row}>
            <Text style={styles.totalCell}>{factura.weight.toFixed(2)}</Text>
            <Text style={styles.totalCell}>{formatNumber(factura.total)}</Text>
            <Text style={styles.totalCell}></Text>
            {mostrarDolares && (
                <>
                    <Text style={styles.totalCell}>USD {factura.totaldolar.toFixed(2)}</Text>
                </>
            )}
            <Text style={styles.headerCell}></Text>
            <Text style={styles.headerCell}></Text>
            <Text style={styles.headerCell}></Text>
        </View>

        <View style={styles.row}>
            <Text style={styles.totalCell}>Total</Text>
            <Text style={styles.totalCell}>{formatNumber(factura.total)}</Text>
            {mostrarDolares && (
                <>
                    <Text style={styles.totalCell}>Total dólares aproximadamente</Text>
                    <Text style={styles.totalCell}>USD {factura.totaldolar.toFixed(2)}</Text>
                </>
            )}
            <Text style={styles.headerCell}></Text>
            <Text style={styles.headerCell}>Total COP</Text>
            <Text style={styles.headerCell}></Text>
        </View>
        <View style={styles.row}>
            <Text style={styles.totalCell}></Text>
            <Text style={styles.totalCell}></Text>
            <Text style={styles.headerCell}></Text>
            <Text style={styles.headerCell}></Text>
            <Text style={styles.headerCell}></Text>
            {mostrarDolares && (
                <>
                    <Text style={styles.totalCell}>Total dólares aproximadamente</Text>
                    <Text style={styles.totalCell}>USD {factura.totaldolar.toFixed(2)}</Text>
                </>
            )}
        </View>
    </Fragment>
    );
};
  
export default InvoiceTableFooter;
