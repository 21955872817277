import { fetchDocuments } from "../../helpers/fetchDocuments";
import { Category } from "../domain/category.interface";

export const loadCategory = async (): Promise<Category[]> => {
  const categories = await fetchDocuments('inventario/categorias', (doc) => ({
    id: doc.id,
    nombre: doc.data.nombre,
    color: doc.data.color,
  }));

  return categories;
};