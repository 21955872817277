import { useQuery } from '@tanstack/react-query';
import { loadTerceros } from '../infraestructure/loadTerceros';

export const ConsultarTerceros = ()=> {
  try {
    const query = useQuery(
      { queryKey: ['terceros'], 
        queryFn: loadTerceros,
        staleTime: 1000 * 60 * 60
      })

    return query;
  } catch (error) {
    console.error(error);
    throw error;
  }
};