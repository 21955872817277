import { Box } from "@mui/material";

const Dashboard = () => {

  return (
    <Box m="20px">

      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">



      </Box>
    </Box>
  );
};

export default Dashboard;