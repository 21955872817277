import { collection, doc, setDoc, updateDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { Item } from '..';

export const UpdateItemFirebase = async (item: Item) => {
    try {
        let itemDocRef;

        const itemData = {
            nombre: item.nombre,
            imagenurl: item.imagenurl,
            valorunitario: item.valorunitario,
            categoria: item.categoria,
            referencia: item.referencia,
            precioventamayorista: item.precioventamayorista,
            precioventaminimo: item.precioventaminimo,
            precioventadistribuidor: item.precioventadistribuidor,
            attributeMap: item.attributeMap
        };

        if (item.id) {
            itemDocRef = doc(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/items`, item.id);
            await updateDoc(itemDocRef, itemData);
        } else {
            itemDocRef = doc(collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/items`));
            await setDoc(itemDocRef, itemData);
            item.id = itemDocRef.id;
        }

    } catch (error) {
        console.error(error);
        alert(error);
    }
}
