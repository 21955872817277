import { createSlice } from '@reduxjs/toolkit';
import { addHours } from 'date-fns';

export const calendarSlice = createSlice({
    name: 'calendar',
    initialState: {
        events: [

        ],
        activeEvent: null
    },
    reducers: {
        onSetActiveEvent: ( state, { payload }) => {
            state.activeEvent = payload;
        },
        onAddNewEvent: ( state, { payload }) => {
            state.events.push( payload );
            state.activeEvent = null;
        },
        onUpdateEvent: ( state, { payload } ) => {
            state.events = state.events.map( event => {
                if ( event._id === payload._id ) {
                    return payload;
                }

                return event;
            });
        },
        onDeleteEvent: ( state ) => {
            if ( state.activeEvent ) {
                state.events = state.events.filter( event => event._id !== state.activeEvent._id );
                state.activeEvent = null;
            }
        },
        setEventos: (state, action ) => {
            const ventasPorFecha = action.payload.reduce((acc, factura) => {
                let fecha = new Date( factura.fecha );
                fecha = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate())
/*
                if (fecha.getHours() < 12) {
                    fecha = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 8)
                } else {
                    if (fecha.getHours() < 18) {
                        fecha = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 13)
                    } else {
                        fecha = new Date(fecha.getFullYear(), fecha.getMonth(), fecha.getDate(), 18)
                    }
                }
*/
                const llave = fecha.getTime() + factura.nombrebodega;

                if (!acc[llave]) {
                  acc[llave] = [];
                }
                acc[llave].push(factura);

                return acc;
            }, {});
            
            const resultado = Object.keys(ventasPorFecha).map((fecha) => {
                const ventasDeFecha = ventasPorFecha[fecha];
                const items = [];

                let fechaCalendario = new Date( ventasDeFecha[0].fecha );
                fechaCalendario = new Date(fechaCalendario.getFullYear(), fechaCalendario.getMonth(), fechaCalendario.getDate(), 8)

                const nombrebodega = ventasDeFecha[0].nombrebodega;

                const totalDeFecha = ventasDeFecha.reduce((acc, factura) => {
                  return acc + factura.total;
                }, 0);

                ventasPorFecha[fecha].forEach(factura => {
                    factura.detalles.forEach(detalle => {
                        if (!items[detalle.nombre]) {
                            items[detalle.nombre] = {total: 0};
                        }
                        items[detalle.nombre].total += detalle.total;
                    });
                });

                let resultadoItems = '';
                Object.keys(items).forEach(item => {
                    resultadoItems += item + ': ' + items[item].total + '\n';
                });

//                const lastView = localStorage.getItem('lastView');
//                     title: new Intl.NumberFormat('es-MX').format(totalDeFecha) + (lastView === 'week' ? '\n' + resultadoItems : ''),
                return {
                    _id: new Date().getTime(),
                    title: nombrebodega + ': ' + new Intl.NumberFormat('es-CO').format(totalDeFecha),
                    notes: resultadoItems,
                    start:  new Date(Number(fechaCalendario)),
                    end: addHours( new Date(Number(fechaCalendario)), 1 ),
                    bgColor: '#fafafa',
                    user: {
                      _id: '123',
                      name: ''
                    }
                };
            });

            state.events = resultado;
        },

    }
});

export const { onSetActiveEvent, onAddNewEvent, onUpdateEvent, onDeleteEvent, setEventos } = calendarSlice.actions;
