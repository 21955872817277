import React, { useState } from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, IconButton, useTheme } from "@mui/material";
import { tokens } from "../../theme/tokens";
import { Header } from "../../components";
import { Box } from "@mui/material";

import { Tercero, TipoTercero } from '..';

import { TerceroModal } from '../components/terceroModal';
import { updateTerceroFirebase } from '../infraestructure/updateTercerosFirebase';
import { ConsultarTerceros } from '../application/consultarTerceros';

import { GridColDef } from "@mui/x-data-grid";
import { EditOutlined } from '@mui/icons-material';

export const TercerosPage = (): JSX.Element => {
  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState(false);
  const [tercero, setTercero] = useState<Tercero>({
    tipo: TipoTercero.CLIENTEDISTRIBUIDOR,
    DNI: '',
    nombre: '',
    apellido: '',
    codigo: '',
    direccion: '',
    ciudad: '',
    pais: '',
    telefono: '',
    email: ''
  });

  const colors = tokens(theme.palette.mode);

  const queryTerceros = ConsultarTerceros();

  const handleEditButtonClick = (tercero: Tercero) => {
    setTercero(tercero);
    setModalOpen(true);
  };

  const terceroColumns: GridColDef[] = [
    {
      field: "codigo",
      headerName: "Codigo",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
        field: "nombre",
        headerName: "Nombre",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "direccion",
        headerName: "Direccion",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "telefono",
        headerName: "Telefono",
        flex: 1,
        cellClassName: "name-column--cell",
    },

    {
        field: 'edit',
        headerName: 'Editar',
        width: 100,
        renderCell: (params) => (
          <IconButton type="button" sx={{ p: 1 }}
          onClick={() => handleEditButtonClick(params.row)}
          >
          <EditOutlined />
        </IconButton>
        ),
    },
];

  return (
    <Box m="20px">

      <Header title="Terceros" subtitle="" />

      <Box
        m="40px 0 0 0"
        height="65vh"
        // custom css for material ui
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          // 🟧🟧🟧 data filter tool-bar present here 🟧🟧🟧
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <Button variant="contained"
            onClick={() => setModalOpen(true)}>Crear Tercero</Button>
        </Box>

        <DataGrid
          rows={(queryTerceros.data ?? [])}
          columns={terceroColumns}
          components={{ Toolbar: GridToolbar }}
        />

        {modalOpen &&
          <TerceroModal
            open={modalOpen}
            tercero={tercero}
            onClose={() => setModalOpen(false)}
            onSave={(item: Tercero) => {
              updateTerceroFirebase(item);
            }}
          />}
      </Box>
    </Box>
  );
};
