import React, { useState, useEffect } from 'react';
import { Item } from '../domain/item.interface';
import { AttributeMapEditor } from './AttributeMapEditor';
import { Box, Button, ButtonGroup, FormControl, Grid, Typography } from '@mui/material';

interface AttributeMapListProps {
  item: Item;
  onItemUpdate: (updatedItem: Item) => void;
}

export const AttributeMapList: React.FC<AttributeMapListProps> = ({ item, onItemUpdate }) => {
  const [addingNew, setAddingNew] = useState(false);

  const handleAttributeChange = (attributeKey: string, attributeValues: string[]) => {
    const updatedAttributeMap = { ...item.attributeMap };

    updatedAttributeMap[attributeKey] = attributeValues;

    const updatedItem = {
      ...item,
      attributeMap: updatedAttributeMap
    };

    onItemUpdate(updatedItem);    
  };

  return (
    <div>
     
    <Box>
      <Typography variant="h6" gutterBottom>
        Varianzas
      </Typography>
      {!addingNew && (
        <Grid container spacing={2}>
          {Object.entries(item.attributeMap).map(([key, values]) => (
            <Grid item key={key}>
              <AttributeMapEditor
                attributeKey={key}
                attributeValues={values}
                onAttributeChange={handleAttributeChange}
              />
            </Grid>
          ))}
        </Grid>
      )}

      {addingNew && (
        <Grid container spacing={2}>
          <Grid item>
            <AttributeMapEditor
              isNew
              onAttributeChange={handleAttributeChange}
            />
          </Grid>
        </Grid>
      )}
    </Box>

      {!addingNew && (

        <FormControl>
          <Box m="20px" sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ m: "auto" }}>
              <Button onClick={() => setAddingNew(true)}>Adicionar nuevo atributo</Button>

            </ButtonGroup>

          </Box>
        </FormControl>
      )}
    </div>
  );
};
