import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    cod: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    description: {
        width: '50%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%',
    },
});

type InvoiceTableHeaderProps = {
    mostrarDolares: boolean | undefined;
};

const InvoiceTableHeader: React.FC<InvoiceTableHeaderProps> = ({ mostrarDolares }) => (
    <View style={styles.container}>
        <Text style={styles.cod}>Cod</Text>
        <Text style={styles.description}>Artículo</Text>
        <Text style={styles.qty}>Cantidad</Text>
        <Text style={styles.amount}>Precio unit COP</Text>
        <Text style={styles.amount}>Total COP </Text>
        {mostrarDolares && (
            <>
                <Text style={styles.amount}>Precio unit Dólar</Text>
                <Text style={styles.amount}>Total dólares aprox</Text>
            </>
        )}
    </View>
);

export default InvoiceTableHeader;
