import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, ButtonGroup, FormControl } from '@mui/material';

import { Item } from '../../items';
import { AttributeMapList } from '../../items/components/AttributeMapList';
import { CancelButton } from '../../items/components/CancelButton';
import { SaveButton } from '../../items/components/SaveButton';

interface ItemModalProps {
  open: boolean;
  itemToEdit: Item;
  onClose: () => void;
  onSave: (item: Item) => void;
}

interface SelectedAttributes {
  [key: string]: { [value: string]: boolean };
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'scroll',
  height: '80%',
  p: 4,
};

export const VarianceModal: React.FC<ItemModalProps> = ({
                                        open,
                                        itemToEdit,
                                        onClose,
                                        onSave
}: ItemModalProps): JSX.Element => {
  const [selectedAttributes, setSelectedAttributes] = useState<SelectedAttributes>({});
  const [keySelected, setKeySelected] = useState<string>();

  const [attributeMap] = useState<Record<string, string[]>>({});

  const [newAttribute, setNewAttribute] = useState<string>('');

  const handleNewAttributeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewAttribute(event.target.value);
  };
  
  const [ item, setItem ] = useState<Item>({
    id: itemToEdit.id,
    referencia: itemToEdit.referencia,
    nombre: itemToEdit.nombre,
    imagenurl: itemToEdit.imagenurl,
    categoria: itemToEdit.categoria,
    valorunitario: itemToEdit.valorunitario,
    precioventamayorista: itemToEdit.precioventamayorista,
    precioventaminimo: itemToEdit.precioventaminimo,
    precioventadistribuidor: itemToEdit.precioventadistribuidor,
    visible: false,
    selected: false,
    attributeMap: itemToEdit.attributeMap,
  });

  const handleSave = (): void => {
    onSave(item);
    onClose();
  };

  const onItemUpdate = (updatedItem: Item) => {
    setItem(updatedItem);
  };

  const handleCancel = (): void => {
    onClose();
  };

  const handleSelect = (key: string, value: string) => {
    setSelectedAttributes(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [value]: !(prevState[key] && prevState[key][value])
      }
    }));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <h2 id="modal-title">Editar varianzas</h2>

        <AttributeMapList item={itemToEdit} onItemUpdate={onItemUpdate} ></AttributeMapList>

        <FormControl>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ m: "auto" }}>
              <CancelButton onClick={handleCancel} />
              <SaveButton onClick={handleSave} />

            </ButtonGroup>
          </Box>
        </FormControl>
      </Box>
    </Modal>
  );
};
