import { Factura } from "../domain/factura.interface";
import { useCalcularTotalFactura } from './useCalcularTotalFactura';

export const UseActualizarValorUnitarioFactura = (factura: Factura, 
                            detalleId: string, 
                            nuevoValorUnitario: number): Factura => {
    const detalle = factura.detalles.find(detalle => detalle.id === detalleId);

    if (!detalle) {
        throw new Error(`No se encontró el detalle con id ${detalleId} en la factura.`);
    }

    const detalleActualizado = { ...detalle };
    
    detalleActualizado.valorunitario = nuevoValorUnitario;

    detalleActualizado.total = detalleActualizado.cantidad * detalleActualizado.valorunitario;
    
    let detallesActualizados = factura.detalles.map(det => det.id === detalleId ? detalleActualizado : det);
        
    const {total, totalDolares } = useCalcularTotalFactura({ ...factura, detalles: detallesActualizados });

    const facturaActualizada = { ...factura,
        total: total, 
        totaldolar: totalDolares,
        detalles: detallesActualizados };
    
    return facturaActualizada;
}
