import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

type InvoiceTitleProps = {
    title: string;
};

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-end',
    },
    reportTitle: {
        color: '#61dafb',
        letterSpacing: 4,
        fontSize: 25,
        textTransform: 'uppercase',
        textAlign: 'right'
    }
});

const InvoiceTitle: React.FC<InvoiceTitleProps> = ({ title }) => (
    <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}>{title}</Text>
    </View>
);

export default InvoiceTitle;
