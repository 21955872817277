import { AuthRoutes } from '../auth/routes/AuthRoutes';
import { SidebarMenu, Topbar } from '../dashboard/pages';
import { Navigation } from '../dashboard/routes/Navigation';
import { useCheckAuth } from '../hooks';
import { LoadingComponent } from '../ui/components';

export const AppRouter = () => {

  const status = useCheckAuth();

  if ( status === 'checking' ) {
    return <LoadingComponent />
  }

  return (
    (status === 'authenticated') ?
      <>
        <main className="app animate__animated animate__fadeIn animate__faster">
          <SidebarMenu />

          <section className="content">
            <Topbar />
            <Navigation />
          </section>
        </main>
      </>
    :
    <AuthRoutes />
  )
}

