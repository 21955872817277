import React from 'react';
import { Box, Button } from "@mui/material";
import { CreateOutlined } from '@mui/icons-material';
import { Header } from '../../components';

interface CreateItemButtonProps {
  onClick: () => void;
}

export const CreateItemButton: React.FC<CreateItemButtonProps> = ({ onClick }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Header title="ITEMS" subtitle="" />

        <Button
            variant="contained"
            startIcon={<CreateOutlined />}
            onClick={onClick}
            >
        Crear Item
        </Button>
    </Box>
  );
};
