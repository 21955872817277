import { AttributeSelection, Detalle, Factura } from '../domain/factura.interface';
import { Item } from '../../items';
import { UseActualizarFactura } from './UseUpdateFactura';
import { generarIdUnico } from '../../helpers/generarIdUnico';
import { UseUnitPrice } from './useUnitPrice';

export const AddItemFactura = (factura: Factura, 
                               item: Item, 
                               cantidad: number,
                               attributeSelection: AttributeSelection[]) => {
    const { detalles } = factura;
    let facturaModificada;

    const attributesMatch = (detalleAttributes: AttributeSelection[], targetAttributes: AttributeSelection[]): boolean => {
        return targetAttributes.every(targetAttr => 
          detalleAttributes.some(detalleAttr => 
            detalleAttr.key === targetAttr.key && detalleAttr.value === targetAttr.value
          )
        );
      };


      const detalleExistente = detalles.find(detalle => 
        detalle.iditem === item.id && detalle.attributeSelection && attributesMatch(detalle.attributeSelection, attributeSelection)
      );

    if (detalleExistente) {
        const detalleActualizado = {
            ...detalleExistente,
            cantidad: detalleExistente.cantidad + cantidad,
            total: (detalleExistente.cantidad + cantidad) * detalleExistente.valorunitario,
        };
        facturaModificada = {
            ...factura,
            detalles: factura.detalles.map((detalle) =>
                detalle.iditem === item.id ? detalleActualizado : detalle
            ),
        };
    } else {
        const valorUnitario = UseUnitPrice(factura, item);
        const totalLocal = valorUnitario * cantidad;
        let totalDolar = 0;

        if (factura.facturaendolares && factura.valordolar) {
            totalDolar = totalLocal / factura.valordolar;
        }
    
        const nuevoDetalle: Detalle = {
            id: generarIdUnico(),
            iditem: item.id,
            nombre: item.nombre,
            referencia: item.referencia,
            cantidad,
            valorunitario: valorUnitario,
            total: totalLocal,
            totaldolar: totalDolar,
            attributeSelection: attributeSelection
        };
        facturaModificada = {
            ...factura,
            detalles: [...factura.detalles, nuevoDetalle],
        };
    }

    facturaModificada.total = facturaModificada.detalles.reduce((total, detalle) => total + detalle.total, 0);
    if (factura.facturaendolares) {
        facturaModificada.totaldolar = facturaModificada.detalles.reduce((totaldolar, detalle) => totaldolar + detalle.totaldolar, 0);
    } else {
        facturaModificada.totaldolar = 0
    }

    try {
        UseActualizarFactura(facturaModificada);
    } catch (error) {
        alert('Error al agregar el item:' + error);
    }

    return facturaModificada;
};
