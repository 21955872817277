import React from 'react';
import { Button, useTheme } from '@mui/material';
import { tokens } from "../../theme/tokens";

type SaveButtonProps = {
  onSave: () => void;
  textToRegister: string;
};

const SaveButton: React.FC<SaveButtonProps> = ({ onSave, textToRegister}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Button
      onClick={onSave}
      variant='contained' 
      sx={{ backgroundColor: colors.greenAccent[700] }}
    >
      {textToRegister} 
    </Button>
  );
};

export default SaveButton;
