import React, { FC } from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useFacturaStore } from '../../../store/useFacturaStore';

export const ItemFilterComponent: FC = () => {
  const { itemNameFilter, setItemNameFilter } = useFacturaStore(state => state);

  const handleNameFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemNameFilter(event.target.value);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
      <TextField
        id="item-filter"
        label="Buscar"
        variant="outlined"
        value={itemNameFilter}
        onChange={handleNameFilterChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ flexGrow: 1 }}
      />
    </Box>
  );
};
