export enum TipoTercero {
  CLIENTEMAYORISTA = 'CLIENTEMAYORISTA',
  CLIENTEDISTRIBUIDOR = 'CLIENTEDISTRIBUIDOR',
  PROVEEDOR = 'PROVEEDOR',
}

export interface Tercero {
  id?: string;
  tipo: TipoTercero;
  DNI: string;
  nombre: string;
  apellido: string;
  codigo: string;
  direccion: string;
  ciudad: string;
  pais: string;
  telefono: string;
  email: string;
  facturaEnDolares?: boolean;
}
