import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, 
  ButtonGroup, 
  Button, 
  TextField, 
  useTheme } from '@mui/material';
  import { Theme } from "@mui/material/styles";
  import { InputLabel, MenuItem, Select } from '@mui/material';

import { Tercero, TipoTercero } from '..';
import { useModalStyles } from "../../theme/useModalStyles";

interface ItemModalProps {
  open: boolean;
  tercero: Tercero;
  onClose: () => void;
  onSave: (tercero: Tercero) => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'scroll',
  height: '100%',
  p: 4,
};

const getModalStyles = (theme: Theme) => {
  return {
    modal: {
      width: '70%',
      height: '70%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,0.9)',
      margin: 'auto',
      overflowY: 'auto',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      //boxShadow: theme.shadows[5],
      //padding: theme.spacing(2, 4, 3),
      minWidth: 300,
      maxWidth: 500,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
  };
};

export const TerceroModal: React.FC<ItemModalProps> = ({ 
                    open,
                    tercero, 
                    onClose, 
                    onSave}: ItemModalProps): JSX.Element => {

  const theme = useTheme();
  const modalStyles = getModalStyles(theme);
  const classes = useModalStyles(modalStyles);
  
  const [item, setItem] = useState<Tercero>({
    id: tercero.id,
    tipo: TipoTercero.CLIENTEDISTRIBUIDOR,
    DNI: tercero.DNI,
    nombre: tercero.nombre,
    apellido: tercero.apellido,
    codigo: tercero.codigo,
    direccion: tercero.direccion,
    ciudad: tercero.ciudad,
    pais: tercero.pais,
    telefono: tercero.telefono,
    email: tercero.email
  });

  const handleSave = (): void => {
    onSave(item);

    onClose();
  };

  const handleCancel = (): void => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
    >
      <Box className={classes.paper} sx={style}>
        <h2 id="modal-title">Nuevo tercero</h2>

        <TextField
          label="DNI"
          fullWidth
          margin="normal"
          value={item.DNI}
          onChange={(e) => setItem({ ...item, DNI: e.target.value })}
        />

          <InputLabel id="tipo-label">Tipo</InputLabel>
          <Select
            labelId="tipo-label"
            id="tipo"
            value={item.tipo}
            label="Tipo"
            onChange={(e) => setItem({ ...item, tipo: e.target.value as TipoTercero })}
          >
            <MenuItem value={'CLIENTEDISTRIBUIDOR'}>Cliente minorista</MenuItem>
            <MenuItem value={'CLIENTEMAYORISTA'}>Cliente mayorista</MenuItem>
            <MenuItem value={'PROVEEDOR'}>Proveedor</MenuItem>
            <MenuItem value={'CLIENTELIBRE'}>Cliente libre</MenuItem>
          </Select>


        <TextField
          label="Nombre"
          fullWidth
          margin="normal"
          value={item.nombre}
          onChange={(e) => setItem({ ...item, nombre: e.target.value })}
        />

        <TextField
          label="Apellido"
          fullWidth
          margin="normal"
          value={item.apellido}
          onChange={(e) => setItem({ ...item, apellido: e.target.value })}
        />

        <TextField
          label="Codigo"
          fullWidth
          margin="normal"
          value={item.codigo}
          onChange={(e) => setItem({ ...item, codigo: e.target.value })}
        />

        <TextField
          label="Direccion"
          fullWidth
          margin="normal"
          value={item.direccion}
          onChange={(e) => setItem({ ...item, direccion: e.target.value })}
        />

        <TextField
          label="Ciudad"
          fullWidth
          margin="normal"
          value={item.ciudad}
          onChange={(e) => setItem({ ...item, ciudad: e.target.value })}
        />

        <TextField
          label="Pais"
          fullWidth
          margin="normal"
          value={item.pais}
          onChange={(e) => setItem({ ...item, pais: e.target.value })}
        />

        <TextField
          label="Telefono"
          fullWidth
          margin="normal"
          value={item.telefono}
          onChange={(e) =>
            setItem({
              ...item,
              telefono: e.target.value,
            })
          }
        />

      <TextField
          label="Email"
          fullWidth
          margin="normal"
          value={item.email}
          onChange={(e) =>
            setItem({
              ...item,
              email: e.target.value,
            })
          }
        />

        <Box sx={{display: "flex", justifyContent: "center"}}>
          <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{m: "auto"}}>
            <Button onClick={handleCancel}>
              Cancelar
            </Button>
            <Button onClick={handleSave}>
              Guardar
            </Button>
          </ButtonGroup>
        </Box>

      </Box>
    </Modal>
  );
};
