import React, { useState } from 'react';

import { useTheme, Box, Grid, IconButton } from '@mui/material';
import { ItemListSelected } from '.'
import { FacturaResumen } from './FacturaResumen';
import { tokens } from '../../theme/tokens';
import { ItemListNotSelectedComponent } from './items/ItemListNotSelectedComponent';
import { useFacturaStore } from '../../store/useFacturaStore';

import "../../css/index.css";
import { TerceroDocument } from './thirdparty/TerceroDocumentComponent';
import { ArrowBackIosNewOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
import { DeliveryDocument } from './DeliveryDocumentComponent';
import { AdviserDocument } from './AdviserDocumentComponent';

export const DocumentRegisterComponent: React.FC = () => {
    const { getDocumentTypeByFacturaType } = useFacturaStore(state => state);
    const [isExpanded, setIsExpanded] = useState(true);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    }

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Grid
            container
            height="calc(100% - 55px)"
            className='containerFactura'>

            <Grid
                item sm={12} md={isExpanded ? 6 : 1}
                alignItems="center"
                className={`h-100 ${isExpanded ? '' : 'hidden'}`}
                justifyContent="center">

                <IconButton
                    onClick={handleExpandClick}
                    sx={{
                        position: 'absolute',
                        transform: 'translateY(-80%)',
                        zIndex: 1
                    }}
                >
                    {isExpanded ? <ArrowBackIosNewOutlined />
                        : <ArrowForwardIosOutlined />}
                </IconButton>

                <Box
                    sx={{
                        backgroundColor: colors.primary[400],
                        overflow: "auto",
                        height: isExpanded ? "auto" : 0,
                        transition: "height 0.3s ease-in-out"

                    }}
                >
                    <ItemListNotSelectedComponent />
                </Box>
            </Grid>

            <Grid
                item sm={12} md={isExpanded ? 6 : 11}
                alignItems="center"
                justifyContent="center"
                className="h-100 paddingContainer"
            >
                <Box
                    sx={{
                        backgroundColor: colors.primary[400],
                        overflow: "auto"
                    }}
                    className="items-selected"
                >
                    {getDocumentTypeByFacturaType()?.includecustomer && (
                        <TerceroDocument />
                    )}

                    <ItemListSelected />

                    {getDocumentTypeByFacturaType()?.includecustomer && (
                        <>
                            <DeliveryDocument />
                            <AdviserDocument />
                        </>
                    )}
                </Box>

                <Box
                    sx={{
                        backgroundColor: colors.primary[400],
                        overflow: "auto",
                        '&.resume': {
                            
                        }
                    }}
                    className="resume">
                    <FacturaResumen />
                </Box>
            </Grid>
        </Grid>
    )
}
