import { Variance } from '../domain/variance.interface';

import { CreateVarianceFirebase } from '../infraestructure/updateVarianceFirebase';

export const actualizarVariances = async (variance: Variance) => {

    CreateVarianceFirebase(variance);

}

