import * as React from 'react'

import { Typography } from "@mui/material";
import { MenuItem } from "react-pro-sidebar";
import { tokens } from "../../../theme/tokens";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

interface Props {
  menu: {
    icon: React.ElementType;
    path: string;
    title: string;
  };
  selected: string;
  setSelected: (path: string) => void;
}

const SidebarMenuItem = ({ menu, selected, setSelected }: Props) => {
  const themes = useTheme();
  const color = tokens(themes.palette.mode);

  return (
    <MenuItem
      icon={<menu.icon />}
      active={selected === menu.path}
      onClick={() => setSelected(menu.path)}
      style={{ color: color.primary[100] }}
    >
      <Typography>{menu.title}</Typography>

      <Link to={menu.path} />
    </MenuItem>
  );
};

export default SidebarMenuItem;