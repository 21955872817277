import { loadTrm } from '..';
import { useQuery } from '@tanstack/react-query';

export const ConsultarTrm = ()=> {
  try {
    const query = useQuery(
      { queryKey: ['trm'], 
        queryFn: loadTrm,
        staleTime: 100000 * 60 * 60
      })

    return query;
  } catch (error) {
    console.error(error);
    throw error;
  }
};