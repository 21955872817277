import React from 'react';
import { Grid, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox } from '@mui/material';
import { Item } from '../domain/item.interface';
import { AttributeMapList } from './AttributeMapList';

interface VariancesTableOrAttributeMapListProps {
  item: Item;
  variances: any[]; // Asegúrate de que el tipo sea correcto
  attributeMap: Record<string, string[]>;
  handleCheckboxClick: (variable: string, valor: string, selected: boolean) => void;
  handleUpdateItem: (item: Item) => void;
}

export const VariancesTableOrAttributeMapList: React.FC<VariancesTableOrAttributeMapListProps> = ({
  item,
  variances,
  attributeMap,
  handleCheckboxClick,
  handleUpdateItem,
}) => {



  if (!item.id) {
    return (
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 200 }} aria-label="Varianzas">
            <TableHead>
              <TableRow>
                <TableCell>Nombre</TableCell>
                <TableCell align="right">Valores</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variances.map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">
                    <List sx={{ width: '100%', bgcolor: 'background.paper', display: 'flex', flexWrap: 'wrap' }}>
                      {(row.value ?? []).map((valor: any) => {
                        const labelId = `checkbox-list-label-${valor}`;
                        const isChecked = attributeMap[row.name]?.includes(valor);

                        return (
                          <ListItem key={valor} disablePadding sx={{ width: '25%' }}>
                            <ListItemButton role={undefined} onClick={() => handleCheckboxClick(row.name, valor, !isChecked)} dense
                              sx={{
                                display: 'flex',
                                alignItems: 'center', // Asegura que los elementos estén alineados verticalmente
                              }}
                            >
                              <ListItemText id={labelId} primary={`${valor}`} sx={{ mr: 2 }} />
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              </ListItemIcon>
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>

      </Grid>
    );
  } else {
    return <AttributeMapList item={item} onItemUpdate={handleUpdateItem} />;
  }
};
