import { DocumentType, Estado, Estadoorden, Factura } from '../domain/factura.interface';
import { addDocument } from '../../helpers/addDocument';

export const UseAddFactura = async (params: Partial<Factura>): Promise<Factura> => {
    const newFactura: Factura = {
        tipo: params.tipo || DocumentType.VENTAMOSTRADOR,
        fecha: params.fecha || new Date().getTime(),
        consecutivo: params.consecutivo || 0,
        usuarioid: params.usuarioid || '',
        estado: params.estado || Estado.ENPROCESO,
        estadoorden: params.estadoorden || Estadoorden.PENDIENTE,
        detalles: params.detalles || [],
        total: params.total || 0,
        totaldolar: params.totaldolar || 0,
        idbodega: params.idbodega || '0',
        nombrebodega: params.nombrebodega || '',
        ...(params.color !== undefined && { color: params.color }),
        ...(params.tercero !== undefined && { tercero: params.tercero }),
        weight: params.weight || 0,
    }

    try {
        const id = await addDocument('inventario/movimientos', newFactura);        
        newFactura.id = id;
        return newFactura;      
        } catch (error) {
        console.error('Error al agregar la factura:', error);
        throw error;
    }
};