import { create } from 'zustand'
import { Category } from '../category';

type State = {
    categories: Category[];
}

type Actions = {
    setCategories: (facturas: Category[]) => void;
    clearStore: () => void;
}

export const useCategoryStore = create<State & Actions>((set) => ({

    categories: [],

    setCategories: (categories: Category[]) => set(state => ({
        categories: categories
    })),
    clearStore: () => {
        set({}, true)
    }
}))