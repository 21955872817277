import React, { useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, TextField, Select, MenuItem } from '@mui/material';
import { Adviserinformation } from '../domain/factura.interface';
import { useFacturaStore } from '../../store/useFacturaStore';
import { UseActualizarFactura } from '../application/UseUpdateFactura';
import { SelectChangeEvent } from '@mui/material'; // Asegúrate de importar el tipo correcto


export const AdviserDocument: React.FC = () => {
  const { 
    setFactura, 
    factura,
    advisers } = useFacturaStore(state => state);
  

  const [adviser, setAdviser] = useState<Adviserinformation>({
    adviserdni: factura.adviserinformation?.adviserdni,
    advisername: factura.adviserinformation?.advisername || '',
    commissionvalue: factura.adviserinformation?.commissionvalue || 0
  });

  const adviserinformation = factura.adviserinformation || {};

  // Cambia el tipo del evento en handleAdviserChange
  const handleAdviserChange = (event: SelectChangeEvent<any>) => {
    const selectedAdviser = advisers.find(adviser => adviser.id === event.target.value);

    if (selectedAdviser) {
      setAdviser({
        adviserdni: selectedAdviser.dni,
        advisername: selectedAdviser.name || ''
      });
    }
  };

  const handleSave = (): void => {
    setFactura({ ...factura, adviserinformation: adviser });
    UseActualizarFactura(factura);
  };

  useEffect(() => {
    setAdviser({
      adviserdni: adviserinformation.adviserdni,
      advisername: adviserinformation.advisername || '',
      commissionvalue: adviserinformation.commissionvalue || 0
    });
  }, [factura]);

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>Información del asesor</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box >
          <Select
              label="Asesor"
              fullWidth
              value={adviser?.adviserdni || ''}
              onChange={handleAdviserChange}
            >
              {advisers.map(adviser => (
                <MenuItem key={adviser.id} value={adviser.id}>
                  {adviser.name}
                </MenuItem>
              ))}
            </Select>
            
            <TextField
              label="DNI"
              fullWidth
              margin="normal"
              value={adviser.adviserdni}
              onChange={(e) => setAdviser({ ...adviser, adviserdni: e.target.value })}
              />
            <TextField
              label="Nombre"
              fullWidth
              margin="normal"
              value={adviser.advisername}
              onChange={(e) => setAdviser({ ...adviser, advisername: e.target.value })}
              />

            <TextField
              label="Valor comision"
              fullWidth
              margin="normal"
              value={adviser.commissionvalue}
              onChange={(e) => setAdviser({ ...adviser, commissionvalue: Number(e.target.value) })}
              />
              {/* 
            */}
            <Box sx={{ display: "flex", justifyContent: "center" }}>

                <Button onClick={handleSave}>
                  Guardar
                </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
