import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { DocumentType, Estado, Factura } from '../../document/domain/factura.interface';
import { UseSearchFacturasByStateAndDocumentType } from '../../document/application/UseSearchFacturasByStateAndDocumentType';
import { Header } from '../../components';
import FilterComponent from '../components/FiltroComponent';
import TablaComponent from '../components/TablaComponent';


const InventoryMovementList: FC = (): JSX.Element => {
  const [facturas, setFacturas] = useState<Factura[]>([]);
  const [filteredFacturas, setFilteredFacturas] = useState<Factura[]>([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<string | undefined>(undefined);
  const [selectedState, setSelectedState] = useState<Estado>(Estado.FINALIZADA);
  const [selectedDocumentType, setSelectedDocumentType] = useState<DocumentType>(DocumentType.VENTACLIENTE);
  const [selectedAssessor, setSelectedAssessor] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await UseSearchFacturasByStateAndDocumentType(selectedState, selectedDocumentType);
        setFacturas(result);
      } catch (error) {
        console.error('Error al obtener el informe de inventario:', error);
      }
    };

    fetchData();
  }, [selectedState, selectedDocumentType]);

  useEffect(() => {
    let filtered = facturas;

    if (selectedDocumentType === DocumentType.VENTACLIENTE && selectedAssessor) {
      filtered = filtered.filter(factura =>
        factura.adviserinformation?.advisername?.toLowerCase().includes(selectedAssessor.toLowerCase())
      );
    }

    setFilteredFacturas(filtered);
  }, [facturas, selectedAssessor, selectedDocumentType]);

  const handleInvoiceClick = (id: string) => {
    setSelectedInvoiceId(selectedInvoiceId === id ? undefined : id);
  };

  const handleStateChange = (event: SelectChangeEvent<Estado>) => {
    setSelectedState(event.target.value as Estado);
  };

  const handleDocumentTypeChange = (event: SelectChangeEvent<DocumentType>) => {
    setSelectedDocumentType(event.target.value as DocumentType);
    if (event.target.value !== DocumentType.VENTACLIENTE) {
      setSelectedAssessor(''); // Reset the assessor filter if the document type is not VENTACLIENTE
    }
  };

  const handleAssessorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAssessor(event.target.value);
  };

  return (
    <Box m="20px">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Header title="Movimientos" subtitle="" />
        <FilterComponent
          selectedState={selectedState}
          selectedDocumentType={selectedDocumentType}
          selectedAssessor={selectedAssessor}
          onStateChange={handleStateChange}
          onDocumentTypeChange={handleDocumentTypeChange}
          onAssessorChange={handleAssessorChange}
        />
      </Box>

      <Box m="0 0 0 0" height="75vh">
        <TablaComponent
          facturas={filteredFacturas}
          selectedInvoiceId={selectedInvoiceId}
          onInvoiceClick={handleInvoiceClick}
          selectedDocumentType={selectedDocumentType}
        />
      </Box>
    </Box>
  );
};

export default InventoryMovementList;
