import React from 'react';
import { FormControl, Box, ButtonGroup } from '@mui/material';
import { CancelButton } from './CancelButton';
import { SaveButton } from './SaveButton';
import { DeleteButton } from './DeleteButton';

interface FormButtonsGroupProps {
  handleCancel: () => void;
  handleSave: () => void;
  handleDelete: () => void;
}

export const FormButtonsGroup: React.FC<FormButtonsGroupProps> = ({
  handleCancel,
  handleSave,
  handleDelete,
}) => (
  <FormControl>
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{ m: "auto" }}>
        <CancelButton onClick={handleCancel} />
        <SaveButton onClick={handleSave} />
        <DeleteButton onClick={handleDelete} />
      </ButtonGroup>
    </Box>
  </FormControl>
);
