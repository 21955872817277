import { createSlice } from '@reduxjs/toolkit';

export const facturaSlice = createSlice({
    name: 'factura',
    initialState: {
        isSaving: false,
        messageSaved: '',
        itemsFacturables: [],
        itemsSelected: [],
        itemsNoSelected: [],
        facturas: [],
        active: null,
        categorias: []
     },
    reducers: {
        savingNewFactura: ( state ) => {
            state.isSaving = true;
        },
        addNewEmptyFactura: (state, action ) => {
            state.facturas.push( action.payload );
            state.isSaving = false;
        },
        setActiveFactura: (state, action ) => {
            state.active = action.payload;
            state.messageSaved = '';
        },
        setFacturas: (state, action ) => {
            state.facturas = action.payload;
        },
        emptyActiveFactura: (state) => {
            state.active = undefined;
        },
        setSaving: (state ) => {
            state.isSaving = true;
            state.messageSaved = '';
        },
        updateFactura: (state, action ) => { // payload: note
            state.isSaving = false;
            state.facturas = state.facturas.map( note => {

                if ( note.id === action.payload.id ) {
                    return action.payload;
                }

                return note;
            });

            state.messageSaved = `${ action.payload.title }, actualizada correctamente`;
        },
        setPhotosToActiveNote: (state, action) => {
            state.active.imageUrls = [ ...state.active.imageUrls, ...action.payload ]; 
            state.isSaving = false;
        },

        clearFacturasLogout: (state) => {
            state.isSaving = false;
            state.messageSaved = '';
            state.notes = [];
            state.active = null;
        },

        deleteFacturaById: (state, action ) => {
            state.active = null;
            state.notes = state.notes.filter( note => note.id !== action.payload );
        },
        setitemsfacturables: (state, action ) => {
             state.itemsFacturables = action.payload;
        },
        setitemsSelected: (state, action ) => {
            state.itemsSelected = action.payload;
        },
        setitemsNoSelected: (state, action ) => {
        state.itemsNoSelected = action.payload;
        },
        setcategorias: (state, action ) => {
            state.categorias = action.payload;
        },
    }
});


// Action creators are generated for each case reducer function
export const { 
    addNewEmptyFactura,
    clearFacturasLogout,
    deleteFacturaById, 
    savingNewFactura,
    setActiveFactura,
    emptyActiveFactura,
    setFacturas,
    setPhotosToActiveNote,
    setSaving,
    updateFactura,
    setitemsfacturables,
    setitemsSelected,
    setitemsNoSelected,
    setcategorias,
} = facturaSlice.actions;