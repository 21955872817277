import { collection, query, where, getDocs, DocumentSnapshot } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { processDocument } from './processDocument';
import { DocumentType, Estado, Factura } from '../domain/factura.interface';

export const loadDocumentosStartingDate = async (fechaLimite: number): Promise<Factura[]> => {
  const collectionRef = collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/movimientos`);
  const q = query(collectionRef, 
    where("fecha", ">=", fechaLimite),
  );

  const docs = await getDocs(q);

  const facturas = docs.docs.map(doc => processDocument(doc as DocumentSnapshot));

  return facturas.filter(doc => doc.tipo !== DocumentType.INVENTARIO && doc.estado !== Estado.CANCELADA);
};
