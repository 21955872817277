import React from 'react';
import { Button } from '@mui/material';

interface ButtonProps {
  onClick: () => void; // Tipo para la función onClick
}

export const CancelButton: React.FC<ButtonProps> = ({ onClick }) => (
  <Button onClick={onClick} sx={{ margin: '2px' }}>
    Cancelar
  </Button>
);