import InventoryIcon from '@mui/icons-material/Inventory';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import CategoryIcon from '@mui/icons-material/Category';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BalanceIcon from '@mui/icons-material/Balance';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';


export interface MenuItem {
    title: string;
    icon?: React.ComponentType;
    tag?: string;
    path?: string;
  }

export const routes: MenuItem[] = [
    /*{
        title: 'Tests',
        icon: ContactsOutlinedIcon,
        path: '/tests',
    },*/
    {
        title: 'Inicio',
        tag: 'divider'
    },
    {
        title: 'TRM',
        icon: PriceChangeIcon,
        path: '/trm',
    },

    {
        title: 'Gestión de inventario',
        icon: InventoryIcon,
        path: '/inventario',
    },
    {
        title: 'Costo',
        icon: RequestQuoteIcon,
        path: '/ingreso',
    },
    {
        title: 'Venta en mostrador',
        icon: PointOfSaleIcon,
        path: '/ventamostrador',
    },
    {
        title: 'Venta a clientes',
        icon: MonetizationOnIcon,
        path: '/ventacliente',
    },

    {
        title: 'Gestión de datos',
        tag: 'divider'
    },

    {
        title: 'Lista de movimientos',
        icon: PlaylistAddCheckCircleIcon,
        path: '/inventorymovementlist',
    },

    {
        title: 'Lista de inventario',
        icon: PlaylistAddCheckCircleIcon,
        path: '/inventorylist',
    },

    {
        title: 'Lista de ventas',
        icon: PlaylistAddCheckCircleIcon,
        path: '/customersaleslist',
    },
    
    {
        title: 'Variaciones',
        icon: PriceChangeIcon,
        path: '/variances',
    },

    {
        title: 'Categorias',
        icon: CategoryIcon,
        path: '/categories',
    },
    {
        title: 'Items',
        icon: AccountTreeIcon,
        path: '/items',
    },
    
    {
        title: 'Terceros',
        icon: SocialDistanceIcon,
        path: '/terceros',
    },

    {
        title: 'Asesores',
        icon: ConnectWithoutContactIcon,
        path: '/asesores',
    },

    {
        title: 'Informes',
        tag: 'divider'
    },
    {
        title: 'Ventas diarias2',
        icon: CalendarMonthIcon,
        path: '/ventasdiarias',
    },
    {
        title: 'Balance',
        icon: BalanceIcon,
        path: '/balance',
    },
    {
        title: 'Validacion de inventario',
        icon: CrisisAlertIcon,
        path: '/validation',
    }/*,

    {
        title: 'Components',
        tag: 'divider'
    },

    {
        title: 'Utilidades',
        icon: HomeOutlinedIcon,
        path: '/',
    },
    {
        title: 'Componente Base',
        icon: DashboardIcon,
        path: '/base',
    },
    {
        title: 'Otras Utilidades',
        tag: 'divider'
    },
    {
        title: 'Formularios',
        icon: PersonOutlinedIcon,
        path: '/form',
    },
    {
        title: 'Tabla',
        icon: PeopleOutlinedIcon,
        path: '/team',
    },
    {
        title: 'Tabla con Filtros',
        icon: ContactsOutlinedIcon,
        path: '/contacts',
    },*/
]