import React from 'react';
import { FC } from 'react'

import { Navigate, Route, Routes } from "react-router-dom"
import { ItemsPage } from "../../items/pages/ItemsPage"
import { VentasdiariasPage } from '../../ventasdiarias'
import { Dashboard, Team } from "../pages"
import { TercerosPage } from '../../terceros';

import { CategoriesPage } from '../../category';

import { DocumentRegisterPage } from '../../document/pages/DocumentoRegisterPage';
import InventoryReportTable from '../../balance/pages/InventoryReportTable';
import { DocumentType } from '../../document/domain/factura.interface';
import InventoryValidation from '../../balance/pages/InventoryValidation';
import { VariancesPage } from '../../varianzas';
import { AdviserPage } from '../../advisers/pages/AdviserPage';
import SalesToClientList from '../../salestoclientlist/pages/SalesToClientList';
import InventoryList from '../../salestoclientlist/pages/InventoryList';
import { TrmPage } from '../../trm';
import InventoryMovementList from '../../salestoclientlist/pages/InventoryMovementList';

interface Props  extends React.PropsWithChildren<{

}> {}

export const Navigation: FC<Props> = ({ children }) => {

  return (
      <Routes>
        <Route path="/" element={<Dashboard />} />

        <Route path='/trm' element={<TrmPage />}></Route>
        <Route path='/inventario' element={<DocumentRegisterPage documenttype = {DocumentType.INVENTARIO} />}></Route>
        <Route path='/ventamostrador' element={<DocumentRegisterPage documenttype= {DocumentType.VENTAMOSTRADOR} />}></Route>
        <Route path='/ventacliente' element={<DocumentRegisterPage documenttype= {DocumentType.VENTACLIENTE} />}></Route>
        <Route path='/ingreso' element={<DocumentRegisterPage documenttype= {DocumentType.INGRESO} />}></Route>
        <Route path='/ventasdiarias' element={<VentasdiariasPage/>}></Route>
        <Route path='/inventorymovementlist' element={<InventoryMovementList />}></Route>

        <Route path='/inventorylist' element={<InventoryList />}></Route>        
        <Route path='/customersaleslist' element={<SalesToClientList/>}></Route>
        
        <Route path='/variances' element={<VariancesPage />}></Route>
        <Route path='/categories' element={<CategoriesPage/>}></Route>
        <Route path='/items' element={<ItemsPage/>}></Route>
        <Route path='/terceros' element={<TercerosPage/>}></Route>
        <Route path='/asesores' element={<AdviserPage/>}></Route>
        <Route path='/balance' element={<InventoryReportTable/>}></Route>
        <Route path='/validation' element={<InventoryValidation/>}></Route>

        <Route path="/team" element={<Team />} />

        <Route path="/*" element={ <Navigate to="/" /> } />
    </Routes>
  )
}
