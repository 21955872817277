import { createContext, useState, useMemo } from "react";
import { createTheme, Theme } from "@mui/material/styles";
import { themeSettings } from "./themeSettings";

export type Mode = "dark" | "light";

// context for color mode
export const ColorModeContext = createContext({
    // this function allow us to change the color of app globally
    toggleColorMode: () => { },
});

interface ColorModeContextProps {
    toggleColorMode: () => void;
  }
  
export const useThemeMode = (): [Theme, ColorModeContextProps] => {
  const [mode, setMode] = useState<Mode>("dark");

  const colorMode = useMemo<ColorModeContextProps>(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return [theme, colorMode];
}



