import { Factura } from "../domain/factura.interface";

export const useCalcularTotalFactura = (factura: Factura): { total: number, totalDolares: number } => {
    const totalFactura = factura.detalles.reduce((total, detalle) => total + detalle.total, 0);

    let totalDolares = 0;
    if (factura.facturaendolares && factura.valordolar) {
        totalDolares = totalFactura / factura.valordolar;
    }

    return { total: totalFactura, totalDolares };
}
