import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Box, ButtonGroup, Typography, FormControl, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';

import { setActiveFactura, startSaveFactura, emptyActiveFactura } from '../../store/factura';
import { useState } from 'react';
import { useFacturaStore } from '../../store/useFacturaStore';
import { formatNumber } from '../../helpers/formatNumber';
import PrintButton from './PrintButton';
import { Estado } from '../domain/factura.interface';
import SaveButton from './SaveButton';
import CancelInvoiceButton from './CancelInvoiceButton';
import InvoiceStateSection from './invoice/InvoiceStateSection';

export const FacturaResumen = () => {
  const { factura, 
          getDocumentTypeByFacturaType,
          deleteFacturapendiente,
          setInitFactura
        } = useFacturaStore(state => state);
//  const { facturas } = useSelector( state => state.factura );
  const [ingresos, setIngresos] = useState(0)

  const dispatch = useDispatch();

  const filtrarFacturaLista = () => {
/*
    const facturasActualizado = facturas.filter(function(fac) {
      return fac.id !== factura.id 
    });
    */
//    dispatch( setFacturas( facturasActualizado ) );
    dispatch(emptyActiveFactura(factura))
  }

  const onGuardar = (isRecibido) => {
    let facturaUpdated;

    if (isRecibido) {
      facturaUpdated = { ...factura, estado: Estado.FINALIZADA};
      deleteFacturapendiente(factura.id) 
    } else {
      facturaUpdated = { ...factura};
    }

    const detallesFiltrados = facturaUpdated.detalles.filter(detalle => detalle.cantidad !== 0);
    facturaUpdated.detalles = detallesFiltrados;

    dispatch(setActiveFactura(facturaUpdated));
    dispatch(startSaveFactura());
    filtrarFacturaLista();
    setInitFactura()
  }

  const onCancelar = () => {
    const facturaUpdated = { ...factura, estado: Estado.CANCELADA};
    deleteFacturapendiente(factura.id) 

    dispatch(setActiveFactura(facturaUpdated));
    dispatch(startSaveFactura());
    filtrarFacturaLista();
    setInitFactura()
  }

  return (
    <>
      <InvoiceStateSection />
            
        <Grid item sm={12} md={5} alignItems="center">
          <Typography variant="h5">Resumen De Ventas {formatNumber(factura.total)} </Typography>
          {factura.facturaendolares &&
            <Typography variant="h5">USD {formatNumber(factura.totaldolar)} </Typography>
          }

        </Grid>
      <Box 
        sx={{ mx: 2, my: 1, boxShadow: 3 }}>
        <Grid sx={{ px: 2, py: 1 }} container>


          <Grid item sm={12} md={7} alignItems="center">
            {
              getDocumentTypeByFacturaType()?.includeChange  ? (
             <>
             <FormControl fullWidth sx={{ mb: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">Ingresos</InputLabel>
                <OutlinedInput
                  type="number"
                  onKeyUp={ el =>{setIngresos(el.target.value)}}
                  id="outlined-adornment-amount"
                  startAdornment={<InputAdornment position="start"> $ </InputAdornment>}
                  label="Amount"
                />
              </FormControl>
              <Typography>Cambio: {(ingresos > factura.total ? (ingresos - factura.total) : '')}</Typography>
              </>
              ) : null
            } 

            <Box>
              <ButtonGroup>
              <SaveButton onSave={() => onGuardar(false)} 
                          textToRegister={'Actualizar'} />

                <SaveButton onSave={() => onGuardar(true)} 
                          textToRegister= { getDocumentTypeByFacturaType()?.texttoregister }/>

                <PrintButton />
                
                <CancelInvoiceButton onCancelar={ onCancelar }
                        textToRegister={'Cancelar'} />
              </ButtonGroup>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  )  
}
