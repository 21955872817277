import { collection, getDocs } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { Tercero } from '..';

export const loadTerceros = async (): Promise<Tercero[]> => {
  if (!process.env.REACT_APP_CLIENTE_ID) throw new Error('El UID del cliente no existe');

  const collectionRef = collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/terceros`);
  const docs = await getDocs(collectionRef);

  const terceros: Tercero[] = [];
  docs.forEach((doc) => {
    terceros.push(
      { id: doc.id,
        tipo: doc.data().tipo,
        DNI: doc.data().DNI,
        nombre: doc.data().nombre,
        apellido: doc.data().apellido,
        codigo: doc.data().codigo,
        direccion: doc.data().direccion,
        telefono: doc.data().telefono,
        ciudad: doc.data().ciudad,
        pais: doc.data().pais,
        email: doc.data().email
      }
    );
  });
  
  return terceros;
};
