import React, { FC, useState } from 'react';
import { AddOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { useFacturaStore } from '../../store/useFacturaStore';

import { DocumentType, Factura } from '../domain/factura.interface';
import { ModalClientSelector } from './ModalClientSeleccion';
import { ModalColorSelector } from './ModalColorSeleccion';
import { UseAddFactura } from '../application/useAddFactura';

type InventoryPageProps = {
  documentType: DocumentType;
};

const NewDocumentButtonComponent: FC<InventoryPageProps> = ({ documentType }) => {
  const [openSelectCustomer, setOpenSelectCustomer] = useState(false);
  const [openSelectColor, setOpenSelectColor] = useState(false);

  const handleOpenCustomer = () => setOpenSelectCustomer(true);
  const handleCloseCustomer = () => setOpenSelectCustomer(false);

  const handleOpenColor = () => setOpenSelectColor(true);
  const handleCloseColor = () => setOpenSelectColor(false);

  const { setFactura, 
      setFacturaspendientes,
      facturaspendientes,
      getDocumentType } = useFacturaStore(state => state);

  const onClickNewFactura = async (partialFactura: Partial<Factura> = {}) => {
    try {
      const factura = await UseAddFactura({ tipo: documentType, ...partialFactura });
      setOpenSelectColor(false);
      setOpenSelectCustomer(false);
      setFactura(factura);
      setFacturaspendientes([...facturaspendientes, factura]);
    } catch (error) {
      alert(error);
    }
  };
      

  
  const onClickButton = getDocumentType(documentType)?.includecustomer
    ? handleOpenCustomer
    : getDocumentType(documentType)?.includecolor
    ? handleOpenColor
    : () => onClickNewFactura();


  return (
    <>
      <Tooltip title={`Crear nueva factura ${documentType}`}>
          <IconButton
              onClick={onClickButton}
              disabled={false}
              sx={{
                color: 'white',
                backgroundColor: 'error.main',
                ':hover': { backgroundColor: 'error.main', opacity: 0.9 },
                position: "relative",
                bottom: 5,
                p: 1.8,
                mr: 1
              }}
            >
          <AddOutlined sx={{ fontSize: 20 }} />
        </IconButton>

      </Tooltip>

      {getDocumentType(documentType)?.includecustomer && (
        <ModalClientSelector
              open={openSelectCustomer}
              onClose={handleCloseCustomer}
              onClickNewFactura={(partialFactura) => onClickNewFactura(partialFactura)}
              />
      )}

      {getDocumentType(documentType)?.includecolor && (
        <ModalColorSelector
              open={openSelectColor}
              onClose={handleCloseColor}
              onClickNewFactura={(partialFactura) => onClickNewFactura(partialFactura)}
              />
      )}
    </>

  );
};

export default NewDocumentButtonComponent;
