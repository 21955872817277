import React, { useState, useEffect } from 'react';
import { Grid, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useFacturaStore } from '../../../store/useFacturaStore';
import { AttachMoney, DoDisturbOn } from '@mui/icons-material';
import { Estadoorden, Factura } from '../../domain/factura.interface';
import { GridCheckCircleIcon } from '@mui/x-data-grid';

const InvoiceStateSection: React.FC = () => {
  const { factura, getDocumentTypeByFacturaType, setFactura } = useFacturaStore(state => state);
  const [selectedOption, setSelectedOption] = useState<Estadoorden | ''>(factura.estadoorden || '');

  useEffect(() => {
    if (selectedOption) {
      const updatedFactura: Factura = { ...factura, estadoorden: selectedOption };
      setFactura(updatedFactura);
    }
  }, [selectedOption]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value as Estadoorden);
  };

  function renderOption(title: string, description: string, Icon: React.ElementType, estadoOrden: Estadoorden) {
    return (
      <Grid container spacing={2} sx={{ py:1, borderBottom: '1px solid grey' }}>
        <Grid item xs={1}>
          <Icon />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Grid>
        <Grid item xs={5}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="order-status"
              name="order-status"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <FormControlLabel label='' value={estadoOrden} control={<Radio />} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {getDocumentTypeByFacturaType()?.includebillable && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>Estado de la orden</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container>
              <Grid container spacing={2} margin={2}>
                {renderOption("Pago no realizado", "Quiero dejar el stock sin alteraciones.", 
                          DoDisturbOn, Estadoorden.PENDIENTE)}
                {renderOption("Pago en proceso", "Todavía no recibí el dinero, pero quiero reservar el stock para mi cliente.", 
                          GridCheckCircleIcon, Estadoorden.ENPROCESO)}
                {renderOption("Pago recibido", "Todo perfecto! Ya recibí el dinero y quiero descontar el producto de mi stock.", 
                          AttachMoney, Estadoorden.RECIBIDO)}
              </Grid>
            </Container>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default InvoiceStateSection;

