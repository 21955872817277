import { Factura } from "../domain/factura.interface";
import { useCalcularTotalFactura } from './useCalcularTotalFactura';

export const UseActualizarCantidadFactura = (factura: Factura, 
                            detalleId: string, 
                            nuevaCantidad: number | '+' | '-'): Factura => {
    const detalle = factura.detalles.find(detalle => detalle.id === detalleId);

    if (!detalle) {
        throw new Error(`No se encontró el detalle con id ${detalleId} en la factura.`);
    }

    const detalleActualizado = { ...detalle };
    
    if (typeof nuevaCantidad === 'number') {
        detalleActualizado.cantidad = nuevaCantidad;
    } else if (nuevaCantidad === '+') {
        detalleActualizado.cantidad += 1;
    } else if (nuevaCantidad === '-' && detalleActualizado.cantidad > 0) {
        detalleActualizado.cantidad -= 1;
    }

    detalleActualizado.total = detalleActualizado.cantidad * detalleActualizado.valorunitario;

    if (factura.facturaendolares && factura.valordolar) {
        detalleActualizado.totaldolar = detalleActualizado.total / factura.valordolar;
    } else {
        detalleActualizado.totaldolar = 0;
    }
    
    let detallesActualizados = factura.detalles.map(det => det.id === detalleId ? detalleActualizado : det);
    
//    if (detalleActualizado.cantidad === 0) {
//        detallesActualizados = detallesActualizados.filter(det => det.id !== detalleId);
//    }
    
    const {total, totalDolares } = useCalcularTotalFactura({ ...factura, detalles: detallesActualizados });
    const facturaActualizada = { ...factura,
        total: total, 
        totaldolar: totalDolares,
        detalles: detallesActualizados };

    return facturaActualizada;
}
