import { doc, getDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';

export const loadUsuario = async (usuarioid) => {
  const collectionRef = doc(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/usuarios/${usuarioid}`);
  const usuario = await getDoc(collectionRef);
  
  if (usuario.exists()) {
    const bodega = await getDoc(usuario.data().bodega);

    if (!bodega.exists()) {
      throw new Error('La bodega no existe');
    }

    window.localStorage.setItem('bodegaid', usuario.data().bodega.id);
    window.localStorage.setItem('bodeganombre', bodega.data().nombre);

    return { id: usuario.id, bod: {id: usuario.data().bodega.id, nombre: bodega.data().nombre} };
  } else {
    throw new Error('El usuario no existe');
  }
};
