import { setEventos } from './calendarSlice';
import { loadFacturas } from '../../helpers/loadFacturas';
import { Estado } from '../../document/domain/factura.interface';

export function consultarFacturasPagadas() {
    return async (dispatch, getState) => {

        const { uid } = getState().auth;
        if (!uid)
            throw new Error('El UID del usuario no existe');

        const facturas = await loadFacturas(Estado.FINALIZADA);

        dispatch(setEventos(facturas));
    };
}

