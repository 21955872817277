import React, { FC, useEffect, useState } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { InventoryBalance } from '../domain/InventoyBalance';
import { UseGenerateInventoryBalancesreport } from '../../document/application/UseGenerateInventoryBalancesreport';

interface InventoryReportTableProps {

}

const InventoryValidation: FC<InventoryReportTableProps> = (props) => {
  const [fechainventario, setFechainventario] = useState<Date>(new Date());
  const [inventoryBalances, setInventoryBalances] = useState<InventoryBalance[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
//        const { balances} = await UseGenerateInventoryBalancesreport();
//        setInventoryBalances(balances);
      } catch (error) {
        console.error('Error al obtener el informe de inventario:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Referencia</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Categoría</TableCell>
            <TableCell>Inventario Anterior ()</TableCell>
            <TableCell>Ingresos</TableCell>
            <TableCell>Ventas</TableCell>
            <TableCell>Saldo</TableCell>
            <TableCell>Inventario actual (AAMMDD)</TableCell>
            <TableCell>Diferencia</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inventoryBalances.map((balance) => (
            <TableRow key={balance.id}>
              <TableCell>{balance.referencia}</TableCell>
              <TableCell>{balance.nombre}</TableCell>
              <TableCell>{balance.categoria}</TableCell>
              <TableCell>{balance.inventarioinicial}</TableCell>
              <TableCell>{balance.ingresos}</TableCell>
              <TableCell>{balance.ventas}</TableCell>
              <TableCell>{balance.inventarioinicial + balance.ingresos - balance.ventas}</TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InventoryValidation;
