import React from 'react';
import { useState } from 'react';

import Box from '@mui/material/Box';
import { DocumentPendingItem } from '.';
import { useFacturaStore } from '../../store/useFacturaStore';

export const DocumentPendingList = () => {
  const [tabActive, setTabActive] = useState(null)
  const { facturaspendientes } = useFacturaStore(state => state);

  return (
    <Box
      width="calc(100% - 57px)"
      display="flex"
      justifyContent="start"
      zIndex={2}
    >
      <Box
        display="flex"
        overflow="auto"
      >
        {
          (facturaspendientes).map((factura, index) => (
            <DocumentPendingItem key={factura.id} 
                    factura={factura} 
                    tabActive={tabActive} 
                    indexOfFactura={index}
                    />
          ))
        }
      </Box>
    </Box>
  )
}
