import React, { FC } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { DocumentType, Estado } from '../../document/domain/factura.interface';

interface FilterProps {
  selectedState: Estado;
  selectedDocumentType: DocumentType;
  selectedAssessor: string;
  onStateChange: (event: SelectChangeEvent<Estado>) => void;
  onDocumentTypeChange: (event: SelectChangeEvent<DocumentType>) => void;
  onAssessorChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterComponent: FC<FilterProps> = ({
  selectedState,
  selectedDocumentType,
  selectedAssessor,
  onStateChange,
  onDocumentTypeChange,
  onAssessorChange,
}) => {
  return (
    <Box sx={{ display: 'flex', gap: '20px' }}>
      <FormControl variant="outlined" sx={{ minWidth: 200 }}>
        <InputLabel id="document-type-select-label">Tipo de Documento</InputLabel>
        <Select
          labelId="document-type-select-label"
          id="document-type-select"
          value={selectedDocumentType}
          onChange={onDocumentTypeChange}
          label="Tipo de Documento"
        >
          {Object.keys(DocumentType).map((key) => (
            <MenuItem key={key} value={DocumentType[key as keyof typeof DocumentType]}>
              {DocumentType[key as keyof typeof DocumentType]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" sx={{ minWidth: 200 }}>
        <InputLabel id="state-select-label">Estado</InputLabel>
        <Select
          labelId="state-select-label"
          id="state-select"
          value={selectedState}
          onChange={onStateChange}
          label="Estado"
        >
          {Object.keys(Estado).map((key) => (
            <MenuItem key={key} value={Estado[key as keyof typeof Estado]}>
              {Estado[key as keyof typeof Estado]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {selectedDocumentType === DocumentType.VENTACLIENTE && (
        <TextField
          label="Asesor"
          variant="outlined"
          value={selectedAssessor}
          onChange={onAssessorChange}
        />
      )}
    </Box>
  );
};

export default FilterComponent;
