import React, { useState } from 'react';
import { Box, Button, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';

import { Factura } from '../domain/factura.interface';
import { ConsultarTerceros } from '../../terceros/application/consultarTerceros';
import { Tercero } from '../../terceros';


interface ModalSeleccionColorProps {
  open: boolean;
  onClose: () => void;
  onClickNewFactura: (partialFactura: Partial<Factura>) => void;
}

const style: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
  height: '100%',
};

export const ModalClientSelector: React.FC<ModalSeleccionColorProps> = ({
  open,
  onClose,
  onClickNewFactura,
}) => {
  const [filter, setFilter] = useState('');
  const queryTerceros = ConsultarTerceros();

  const handleRowClick = (tercero: Tercero) => {
    onClickNewFactura({ tercero: tercero });
    onClose();
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const filteredTerceros = queryTerceros.data?.filter(tercero =>
    tercero.nombre.toLowerCase().includes(filter.toLowerCase()) ||
    tercero.apellido.toLowerCase().includes(filter.toLowerCase())
  );

  const handleConfirmClick = () => {
    onClickNewFactura({});
    onClose();
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, flexDirection: 'column' }}>

        <Box
          sx={{
            width: 470,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <TextField
              label="Filtrar Clientes"
              variant="outlined"
              value={filter}
              onChange={handleFilterChange}
              style={{ marginBottom: '20px' }}
            />

          <Typography id="modal-modal-title" variant="h6" component="h2">
            Selecciona un cliente
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tipo</TableCell>
                  <TableCell>DNI</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Apellido</TableCell>
                  <TableCell>Dirección</TableCell>
                  <TableCell>Ciudad</TableCell>
                  <TableCell>País</TableCell>
                  <TableCell>Teléfono</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(filteredTerceros ?? []).map((tercero) => (
                  <TableRow key={tercero.id} onClick={() => handleRowClick(tercero)}>
                    <TableCell>{tercero.tipo}</TableCell>
                    <TableCell>{tercero.DNI}</TableCell>
                    <TableCell>{tercero.nombre}</TableCell>
                    <TableCell>{tercero.apellido}</TableCell>
                    <TableCell>{tercero.direccion}</TableCell>
                    <TableCell>{tercero.ciudad}</TableCell>
                    <TableCell>{tercero.pais}</TableCell>
                    <TableCell>{tercero.telefono}</TableCell>
                    <TableCell>{tercero.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button onClick={handleConfirmClick}>Continuar sin cliente</Button>

        </Box>
      </Box>
    </Modal>
  );
};