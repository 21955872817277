import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Detalle } from '../../domain/factura.interface';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    cod: {
        width: '10%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    description: {
        width: '50%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
});

// Define the type for each item in the items array
type InvoiceItem = {
    sno: number; // Assuming sno is a number
    desc: string;
    qty: number;
    rate: number;
};

// Define the type for the props
type InvoiceTableRowProps = {
    detalles: Detalle[];
    mostrarDolares: boolean | undefined;

};

const InvoiceTableRow: React.FC<InvoiceTableRowProps> = ({ detalles, mostrarDolares }) => {
    const rows = detalles.map(d => 
        <View style={styles.row} key={d.referencia}>
            <Text style={styles.cod}>{d.referencia}</Text>
            <Text style={styles.description}>{d.nombre}</Text>
            <Text style={styles.qty}>{d.cantidad}</Text>
            <Text style={styles.rate}>{d.valorunitario.toFixed(2)}</Text>
            <Text style={styles.amount}>{d.total.toFixed(2)}</Text>
            {mostrarDolares && (
                <>
                    <Text style={styles.rate}>{(d.totaldolar / d.cantidad || 0).toFixed(2)}</Text>
                    <Text style={styles.amount}>{(d.totaldolar || 0).toFixed(2)}</Text>
                </>
            )}
        </View>
    );
    return (<Fragment>{rows}</Fragment>);
};

export default InvoiceTableRow;
