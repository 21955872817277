import ReactDOM from 'react-dom/client'
import React from 'react'
import InventarioApp from './InventarioApp';
import './css/theme.css';
import './css/atoms/_button.css';
import './css/elements/objects/_container.css';
import './css/elements/molecules/_form.css';
import './css/calendar.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const root = ReactDOM.createRoot(document.getElementById('root'));

const client = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={ client }>
      <ReactQueryDevtools />

        <InventarioApp />
    </QueryClientProvider>

  </React.StrictMode>
);