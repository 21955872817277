import { collection, query, where, getDocs, QueryDocumentSnapshot } from 'firebase/firestore/lite';
import { FirebaseDB } from '../firebase/config';
import { Factura } from '../document/domain/factura.interface';

export const loadFacturas = async (estado: string): Promise<Factura[]> => {
  if (!process.env.REACT_APP_CLIENTE_ID) throw new Error('El UID del cliente no existe');

  const collectionRef = collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/movimientos`);
  const q = query(collectionRef , where("estado", "==", estado));
  const docs = await getDocs(q);

  const facturas: Factura[] = [];
  docs.forEach((doc: QueryDocumentSnapshot) => {
    facturas.push({ 
      ...doc.data() as Factura,
      id: doc.id, 
      facturaendolares: doc.data().facturaendolares || false,
    });
  });
  return facturas;
};