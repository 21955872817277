import { fetchDocuments } from "../../helpers/fetchDocuments";

export const loadVariances = async () => {
  const variances = await fetchDocuments('inventario/variances', (doc) => ({
    id: doc.id,
    name: doc.data.name,
    value: doc.data.value,
  }));

  return variances;
};
