import Box from '@mui/material/Box';

export const ImageGallery = ({ imagenurl }) => {

  return (
    <Box sx={{
//      width: '100%',
      height: '100px',
      overflow: 'hidden',
      borderRadius: '15px',
      marginBottom: '5px'
    }}>
      <img
        style={{height: '100%'}}
        src={`${imagenurl}`}
        srcSet={`${imagenurl}`}
        alt="Producto Imagen"
        loading="lazy"
      />
    </Box>
  );
}