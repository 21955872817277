import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Box, 
  ButtonGroup, 
  Button, 
  TextField, 
  useTheme } from '@mui/material';
  import { Theme } from "@mui/material/styles";

import { useModalStyles } from "../../theme/useModalStyles";
import { Adviser } from '../domain/adviser.interface';

interface ItemModalProps {
  open: boolean;
  adviser: Adviser;
  onClose: () => void;
  onSave: (adviser: Adviser) => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'scroll',
  height: '100%',
  p: 4,
};

const getModalStyles = (theme: Theme) => {
  return {
    modal: {
      width: '70%',
      height: '70%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', 
      backgroundColor: 'rgba(0,0,0,0.9)',
      margin: 'auto',
      overflowY: 'auto',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      //boxShadow: theme.shadows[5],
      //padding: theme.spacing(2, 4, 3),
      minWidth: 300,
      maxWidth: 500,
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
  };
};

export const AdviserModal: React.FC<ItemModalProps> = ({ 
                    open,
                    adviser, 
                    onClose, 
                    onSave}: ItemModalProps): JSX.Element => {

  const theme = useTheme();
  const modalStyles = getModalStyles(theme);
  const classes = useModalStyles(modalStyles);
  
  const [item, setItem] = useState<Adviser>({
    id: adviser.id,
    dni: adviser.dni,
    name: adviser.name
  });

  const handleSave = (): void => {
    onSave(item);

    onClose();
  };

  const handleCancel = (): void => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.modal}
    >
      <Box className={classes.paper} sx={style}>
        <h2 id="modal-title">Nuevo asesor</h2>

        <TextField
          label="DNI"
          fullWidth
          margin="normal"
          value={item.dni}
          onChange={(e) => setItem({ ...item, dni: e.target.value })}
        />


        <TextField
          label="Nombre"
          fullWidth
          margin="normal"
          value={item.name}
          onChange={(e) => setItem({ ...item, name: e.target.value })}
        />

        <Box sx={{display: "flex", justifyContent: "center"}}>
          <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{m: "auto"}}>
            <Button onClick={handleCancel}>
              Cancelar
            </Button>
            <Button onClick={handleSave}>
              Guardar
            </Button>
          </ButtonGroup>
        </Box>

      </Box>
    </Modal>
  );
};
