import { fetchDocuments } from "../../helpers/fetchDocuments";

export const loadTrm = async () => {
  const trm = await fetchDocuments('inventario/trm', (doc) => ({
    id: doc.id,
    value: doc.data.value,
  }));

  return trm.length > 0 ? trm[0] : { id: null, value: 0 };
};
