import { ColorModeContext, useThemeMode } from './theme';
import { AppRouter } from './router/AppRouter';

import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material'; // reset all default css
import { BrowserRouter } from "react-router-dom";

import { store } from './store'

const InventarioApp = () => {

  const [theme, colorMode] = useThemeMode();

  return (
    <ColorModeContext.Provider value={colorMode}>
      <Provider store={ store }>
        <ThemeProvider theme={ theme }>
          {/* Rest CSS */}
          <CssBaseline />

          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </ThemeProvider>

      </Provider>
    </ColorModeContext.Provider>
  )
}

export default InventarioApp