import { collection, doc, setDoc, DocumentData } from 'firebase/firestore/lite';

import { FirebaseDB } from '../firebase/config';

export const addDocument = async (
  collectionPath: string,
  data: DocumentData
): Promise<string> => {
  if (!process.env.REACT_APP_CLIENTE_ID) throw new Error('El UID del cliente no existe');

  const newDoc = doc( collection( FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/${collectionPath}`));
  try {
    await setDoc( newDoc, data);
    return newDoc.id;
  } catch (error) {
    throw new Error('Error al agregar el documento: ' + error);
  }
};
