import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Variance } from '../domain/variance.interface';

interface NewVarianceFormProps {
  onCreateVariance: (newVariance: Variance) => void;
}

const VariancesNewPage: React.FC<NewVarianceFormProps> = ({ onCreateVariance }) => {
    const [name, setName] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [valuesList, setValuesList] = useState<string[]>([]);
  
    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    };
  
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };
  
    const handleAddValue = () => {
      if (value) {
        setValuesList([...valuesList, value]);
        setValue('');
      }
    };

    const handleCreateVariance = () => {
      if (name && valuesList.length > 0) {
        const newVariance: Variance = {
          name: name,
          value: valuesList,
        };
        onCreateVariance(newVariance);
        setName('');
        setValuesList([]);
      } else {
        alert('Por favor, complete todos los campos.');
      }
    };
  
    return (
      <Box sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Nombre"
            variant="outlined"
            value={name}
            onChange={handleNameChange}
          />
          <TextField
            label="Valor"
            variant="outlined"
            value={value}
            onChange={handleValueChange}
          />
          <Button variant="contained" onClick={handleAddValue}>
            Agregar Valor
          </Button>
          {valuesList.map((val, index) => (
            <Box key={index}>{val}</Box>
          ))}
          <Button variant="contained" onClick={handleCreateVariance}>
            Crear
          </Button>
        </Box>
      </Box>
    );
};

export default VariancesNewPage;
