import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { tilesColors } from '../../helpers/colores';
import { Factura } from '../domain/factura.interface';


interface ModalSeleccionColorProps {
  open: boolean;
  onClose: () => void;
  onClickNewFactura: (partialFactura: Partial<Factura>) => void;
}

const style: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
  height: '100%',
};

export const ModalColorSelector: React.FC<ModalSeleccionColorProps> = ({ open, onClose,  onClickNewFactura }) => {
  return (
    <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
      <Box sx={style}>
        <Box
          sx={{
            width: 470,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            <Typography id="modal-modal-title" variant="h6" component="h2">Selecciona un color que identifique el cliente</Typography>
            <Box id="modal-modal-description" sx={{ mt: 2, display: 'flex', flexWrap: 'wrap' }}>
              {
                tilesColors.map(itemColor => (
                  <Box  key={itemColor.color} 
                        sx={{m: 1, 
                        width: 50, 
                        height: 50, 
                        bgcolor: itemColor.color, 
                        cursor: "pointer"}} 
                        onClick={() => {onClickNewFactura(
                          {color: itemColor.color}
                        )}}></Box>
                ))
              }
            </Box>
          </Box>
          </Box>
        </Modal>
  );
};