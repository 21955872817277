import { Factura } from '../domain/factura.interface';
import { updateDocument } from '../../helpers/updateDocument';

export const updateFactura = async (factura: Factura) => {
  try {
    if (factura.id !== undefined) {
      updateDocument('inventario/movimientos', factura, factura.id);
    }
  } catch (error) {
    console.error('Error al agregar el factura:', error);
    throw error;
  }
};