import * as React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import ItemSelected from './ItemSelected';
import { useFacturaStore } from '../../../store/useFacturaStore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ItemInventarioProps {

}

export const ItemListSelected: React.FC<ItemInventarioProps> = () => {
  const { factura, getDocumentTypeByFacturaType } = useFacturaStore(state => state);

  const amountEditable = getDocumentTypeByFacturaType()?.amounteditable;

  return (
    <>
      <Accordion sx={{ margin: 0 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ margin: 0 }}
        >
          <Typography>Items {factura.tipo} - Productos: { factura.detalles.length }</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ margin: 0 }}>

          {amountEditable ? (
            <TableContainer component={Paper} sx={{ mx: 4, mt: 2, marginLeft: '0px' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: '30%', padding: 0 }}>Nombre</TableCell>
                    <TableCell align="right" style={{ width: '30%', padding: 0 }}>Cantidad</TableCell>
                    <TableCell align="right">Val Unit</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {factura.detalles.map((detalle) => (
                    <ItemSelected key={detalle.id} detalle={detalle} />

                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" overflow="auto">
              {
                factura.detalles.length > 0 ?
                  factura.detalles.map(detalle => (
                    <ItemSelected key={detalle.nombre} detalle={detalle} />
                  )) :
                  <Box sx={{ margin: '20px 0px' }}>
                    <Typography fontSize={20} fontWeight='light' >No hay items seleccionados</Typography>
                  </Box>
              }
            </Box>
          )}                        
          </AccordionDetails>
      </Accordion>

    </>)
}
