import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

type InvoiceLogoProps = {
};

const styles = StyleSheet.create({
    titleContainer: {
        flexDirection: 'row',
        marginTop: 24,
        alignItems: 'center',
    },
    logo: {
        width: 74,
        height: 66,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
});

const InvoiceLogo: React.FC<InvoiceLogoProps> = () => (
    <View style={styles.titleContainer}>
        <Image style={styles.logo} src={`../../logo.png`} />
    </View>
);

export default InvoiceLogo;
