import { loadDocumentosStartingDate } from '../infraestructure/loadDocumentosStartingDate';

import { Factura } from '../domain/factura.interface';
import { UseConsultarItemsBalance } from '../../items/application/UseConsultarItemsBalance';
import { InventoryBalance } from '../../balance/domain/InventoyBalance';
import { Itembalance } from '../../items/domain/itembalance.interface';

export const UseGenerateInventoryBalancesreport = async (inventoryDate: number, selectedInventory: Factura): Promise<{ balances: InventoryBalance[] }> => {
  const itembalances: Itembalance[] = await UseConsultarItemsBalance();
//  const inventory = await loadLastDocumentTypeInventory();
  
  if (!selectedInventory) {
    throw new Error('No se encontró un inventario para generar el reporte');
  }

  const documents = await loadDocumentosStartingDate(inventoryDate);
  
  const inventoryBalances: InventoryBalance[] = itembalances.map(item => {
    const inventarioInicialTotal = selectedInventory.detalles.reduce((total, detalle) => {
      if (detalle.iditem === item.id) {
        return total + detalle.cantidad;
      }
      return total;
    }, 0);

    const balance: InventoryBalance = {
      id: item.id,
      referencia: item.referencia,
      nombre: item.nombre,
      inventarioinicial: inventarioInicialTotal,
      ingresos: 0,
      ventas: 0,
      attributes: [],
    };

    if (item.attributes && typeof item.attributes === 'object') {
      item.attributes.forEach(attribute => {
        const cantidadTotalDetalle = selectedInventory.detalles
          .filter(detalle => detalle.iditem === item.id)
          .reduce((total, detalle) => {
            if (detalle.attributeSelection) {
              const matchingAttribute = detalle.attributeSelection.find(selection =>
                selection.key === Object.keys(attribute)[0] && selection.value === attribute[Object.keys(attribute)[0]]
              );
              if (matchingAttribute) {
                return total + detalle.cantidad;
              }
            }
            return total;
          }, 0);

        balance.attributes.push({
          attributes: attribute,
          inventarioinicial: cantidadTotalDetalle,
          ingresos: 0,
          ventas: 0,
        });
      });
    }
    
    return balance;
  });

  const updateBalanceValues = (doc: Factura, operation: 'ingresos' | 'ventas') => {
    doc.detalles.forEach(detalle => {
      const balanceIndex = inventoryBalances.findIndex(balance => balance.id === detalle.iditem);

      if (balanceIndex !== -1) {
        inventoryBalances[balanceIndex][operation] += detalle.cantidad;

        inventoryBalances[balanceIndex].attributes.forEach(attribute => {
          
          const cantidadTotalDetalle = doc.detalles
            .filter(detalle => detalle.iditem === inventoryBalances[balanceIndex].id)
            .reduce((total, detalle) => {
              if (detalle.attributeSelection) {
                const matchingAttribute = detalle.attributeSelection.find(selection =>
                  // Accedemos a las claves y valores de attribute de manera adecuada
                  selection.key === Object.keys(attribute.attributes)[0] &&
                  selection.value === attribute.attributes[Object.keys(attribute.attributes)[0]]
                );
                if (matchingAttribute) {
                  return total + detalle.cantidad;
                }
              }
              return total;
            }, 0);
            console.log(cantidadTotalDetalle)
  /*
          balance.attributes.push({
            attributes: attribute,
            inventarioinicial: cantidadTotalDetalle,
            ingresos: 0,
            ventas: 0,
          });
          */
        });

        
      } else {
        inventoryBalances.push({
          id: detalle.iditem,
          nombre: detalle.nombre,
          referencia: detalle.referencia,
          inventarioinicial: 0,
          ingresos: operation === 'ingresos' ? detalle.cantidad : 0,
          ventas: operation === 'ventas' ? detalle.cantidad : 0,
          attributes: []
        });
      }
    });
  };

  documents.forEach(doc => {
    if (doc.tipo === 'INGRESO') {
      updateBalanceValues(doc, 'ingresos');
    } else if (doc.tipo === 'VENTACLIENTE' || doc.tipo === 'VENTAMOSTRADOR') {
      updateBalanceValues(doc, 'ventas');
    }
  });

  const filteredBalance = inventoryBalances.filter(balance => balance.inventarioinicial > 0 || balance.ingresos > 0 || balance.ventas > 0);

  return { balances: filteredBalance };
};
