import { doc, deleteDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';

export const deleteItemFirebase = async (itemId: string) => {
    try {
        const itemDocRef = doc(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/items`, itemId);
        await deleteDoc(itemDocRef);
    } catch (error) {
        alert("Error al eliminar el item: " + error);
    }
}