import React, { useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import { Variance } from '../domain/variance.interface';

interface EditVarianceFormProps {
  variance: Variance;
  onUpdateVariance: (updatedVariance: Variance) => void;
}

const VariancesEditPage: React.FC<EditVarianceFormProps> = ({ variance, onUpdateVariance }) => {
  const [id, setId] = useState<string>(variance.id ?? '');
  const [name, setName] = useState<string>(variance.name);
  const [values, setValues] = useState<string[]>(variance.value);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedValues = [...values];
    updatedValues[index] = event.target.value;
    setValues(updatedValues);
  };

  const handleDeleteValue = (index: number) => {
    const updatedValues = [...values];
    updatedValues.splice(index, 1);
    setValues(updatedValues);
  };

  const handleAddValue = () => {
    setValues([...values, '']);
  };

  const handleSaveVariance = () => {
    if (name && values.length > 0) {
      const updatedVariance: Variance = {
        id: id,
        name: name,
        value: values,
      };
      onUpdateVariance(updatedVariance);
    } else {
      alert('Por favor, complete todos los campos.');
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="Nombre"
          variant="outlined"
          value={name}
          onChange={handleNameChange}
        />
        {values.map((val, index) => (
          <Box key={index} sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label={`Valor ${index + 1}`}
              variant="outlined"
              value={val}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleValueChange(event, index)}
            />
            <Button variant="contained" onClick={() => handleDeleteValue(index)}>
              Eliminar
            </Button>
          </Box>
        ))}
        <Button variant="contained" onClick={handleAddValue}>
          Agregar Valor
        </Button>
        <Button variant="contained" onClick={handleSaveVariance}>
          Guardar
        </Button>
      </Box>
    </Box>
  );
};

export default VariancesEditPage;
