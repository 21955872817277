import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth';
import { facturaSlice } from './factura';
import { uiSlice } from './';
import { calendarSlice } from '../ventasdiarias';
import { itemSlice } from '../items';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    factura: facturaSlice.reducer,
    calendar: calendarSlice.reducer,
    ui: uiSlice.reducer,
    item: itemSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});