import React, { useRef, useState } from 'react';
import { gsap } from 'gsap';

import { tokens } from "../../theme/tokens";
import { Box, useTheme, Typography, Icon } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import InventoryIcon from '@mui/icons-material/Inventory';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useFacturaStore } from '../../store/useFacturaStore';
import { Factura } from '../domain/factura.interface';

interface FacturaListItemProps {
  factura: Factura;
  tabActive: any;
  indexOfFactura: number;
  //  setTabActive: (tab: number) => void;
}

export const DocumentPendingItem: React.FC<FacturaListItemProps> = ({ factura, tabActive, indexOfFactura }) => {
  const boxElement = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const facturaStore = useFacturaStore();
  const colorPar = '#13C0DA'; // Color para facturas con ID par
  const colorImpar = '#009ECA'; // Color para facturas con ID impar

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const tl = useRef(gsap.timeline());

  const getHora = () => {
    const newDate = new Date(factura.fecha);
    return newDate.getHours() + ':' + newDate.getMinutes();
  }

  const animarCaja = () => {
    tl.current.to(boxElement.current, { y: -10, duration: 0.2, ease: 'ease-out' }).
      to(boxElement.current, { y: 0, duration: 1, ease: 'bounce-out' })
  }

  const onClickFactura = () => {
    facturaStore.setFactura(factura);
  }

  function getIconByTipo(tipo: string) {
    switch (tipo) {
      case 'VENTACLIENTE':
        return MonetizationOnIcon;
      case 'INVENTARIO':
        return InventoryIcon;
      case 'INGRESO':
        return RequestQuoteIcon;
      default:
        return null;
    }
  }


  return (
    <Box
    key={factura.id}
    ref={boxElement}
    sx={{
      display: 'flex',
      alignItems: 'center',
      mr: 0.5,
      cursor: 'pointer',
      backgroundColor: factura.color 
            ? factura.color 
            : indexOfFactura % 2 === 0 ? colorPar : colorImpar,
      border: '2px solid transparent', // Borde transparente por defecto
      '&:hover': {
        borderColor: colors.grey[200], // Cambia el color del borde al pasar el mouse
      },
      ...(tabActive === factura.id && {
        borderColor: colors.grey[100], // Resalta el borde si el tab está activo
        borderWidth: '4px',
      }),
      // Intercalar los colores de fondo, si es necesario
    }}
    onClick={() => {
      // setTabActive(factura.id)
      onClickFactura();
      animarCaja();
    }}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
  >
      {isHovered && (
          <div style={{ position: 'absolute', top: '-20px', backgroundColor: 'grey', padding: '4px' }}>
            <Typography variant="body2">{ factura.tipo }</Typography>
          </div>
        )}

      <Icon
        component={getIconByTipo(factura.tipo) as React.ElementType}
        sx={{ mx: 0.4, my: (tabActive === factura.id ? 0.3 : 0.8) }}
        title={factura.tipo} // Add title attribute for tooltip
      />
      <Typography
        variant="h6"
        sx={{ mx: 0.4, my: (tabActive === factura.id ? 0.3 : 0.8) }}
      >
        {factura.tercero != undefined
          ? (factura.tercero?.nombre.length > 15 ? factura.tercero?.nombre.slice(0, 15) + '...' : factura.tercero?.nombre)
          : getHora()}
      </Typography>
    </Box>
  )
}


