import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { Factura } from '../../domain/factura.interface';

type InvoiceNoProps = {
    factura: Factura;
};

const styles = StyleSheet.create({
    invoiceNoContainer: {
        marginTop: 10,
        marginLeft: 50,
        justifyContent: 'flex-end',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        paddingTop: 3,
        paddingBottom: 3
    },

});

const InvoiceNo: React.FC<InvoiceNoProps> = ({ factura }) => (
    <Fragment>
        <View style={styles.invoiceNoContainer}>
            <Text>No Remisión: {factura.consecutivo}</Text>
            <Text>Fecha: { new Date(factura.fecha).toLocaleDateString() } </Text>
        </View>
    </Fragment>
);

export default InvoiceNo;
