import React, { FC } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { Factura, DocumentType } from '../../document/domain/factura.interface';
import { getDate } from '../../helpers/getDate';
import InvoiceDetailRow from '../components/InvoiceDetailRow';
import { formatCurrency } from '../../helpers/formatCurrency';

interface TableProps {
  facturas: Factura[];
  selectedInvoiceId: string | undefined;
  onInvoiceClick: (id: string) => void;
  selectedDocumentType: DocumentType;
}

const TablaComponent: FC<TableProps> = ({ facturas, selectedInvoiceId, onInvoiceClick, selectedDocumentType }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Consecutivo</TableCell>
            <TableCell>Fecha</TableCell>
            <TableCell>Tercero</TableCell>
            <TableCell>Productos</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Total</TableCell>
            {selectedDocumentType === DocumentType.VENTACLIENTE && <TableCell>Asesor</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {facturas.map((invoice) => (
            <React.Fragment key={invoice.id}>
              <TableRow>
                <TableCell>{invoice.consecutivo}</TableCell>
                <TableCell>{getDate(invoice.fecha)}</TableCell>
                <TableCell>{invoice.tercero?.nombre}</TableCell>
                <TableCell>
                  <IconButton onClick={() => onInvoiceClick(invoice.id ?? '')}>
                    {selectedInvoiceId === invoice.id ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                  {invoice.detalles?.length}
                </TableCell>
                <TableCell>{invoice.estado}</TableCell>
                <TableCell>{formatCurrency(invoice.total)}</TableCell>
                {selectedDocumentType === DocumentType.VENTACLIENTE && <TableCell>{invoice.adviserinformation?.advisername}</TableCell>}
              </TableRow>
              {selectedInvoiceId === invoice.id && <InvoiceDetailRow factura={invoice} />}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablaComponent;
