import { Tercero } from "../../terceros";

export interface AttributeSelection {
    key: string;
    value: string;
  }

export enum DocumentType {
    VENTAMOSTRADOR = 'VENTAMOSTRADOR',
    VENTACLIENTE = 'VENTACLIENTE',
    INGRESO = 'INGRESO',
    INVENTARIO = 'INVENTARIO',
  }
  
export enum Tipo {
    CLIENTE = 'CLIENTE',
    PROVEEDOR = 'PROVEEDOR',
}

export enum Estadoorden {
    PENDIENTE = 'PENDIENTE',  // Quiero dejar el stock sin alteraciones.
    ENPROCESO = 'PAGOENPROCESO', // Todavía no recibí el dinero, pero quiero reservar el stock para mi cliente.
    RECIBIDO = 'PAGORECIBIDO', // Todo perfecto! Ya recibí el dinero y quiero descontar el producto de mi stock.
}

export enum Estado {
    ENPROCESO = 'ENPROCESO', // Todavía no recibí el dinero, pero quiero reservar el stock para mi cliente.
    FINALIZADA = 'FINALIZADA', // Todo perfecto! Ya recibí el dinero y quiero descontar el producto de mi stock.
    CANCELADA = 'CANCELADA', // Cancelado.
}

export interface DetalleItem {
    [key: string]: string;
}

export interface Detalle {
    id: string;
    iditem: string;
    referencia: string;
    nombre: string;
    cantidad: number;
    valorunitario: number;
    total: number;
    totaldolar: number;
    imagenurl?: string;
//    variances?: DetalleItem[] 
    attributeSelection?: AttributeSelection[]
}

//Entrega
export interface Deliveryinformation {
    street?: string;
    number?: string;
    apartment?: string;
    postalcode?: string;
    neighborhood?: string;
    city?: string;
    province?: string;
}

export interface Adviserinformation {
    adviserdni?: string;
    advisername?: string;
    commissionvalue?: number;
}

    export interface Factura {
        id?: string;

        tipo: DocumentType;
        fecha: number;
        consecutivo: number;
        usuarioid: string;
        estado: Estado;
        estadoorden: Estadoorden;
        detalles: Detalle[];
        color?: string;
        total: number;
        totaldolar: number;
        idbodega: string;
        nombrebodega: string;
        facturaendolares?: boolean;
        valordolar?: number;
        tercero?: Tercero
        deliveryinformation?: Deliveryinformation
        adviserinformation?: Adviserinformation
        paymentreference?: string;
        weight: number;
    }
