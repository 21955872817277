import { collection, query, where, getDocs, DocumentSnapshot } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { processDocument } from './processDocument';
import { DocumentType, Estado, Factura } from '../domain/factura.interface';

export const loadInvoicesByStateAndDocumentType = async (
      estado: Estado,
      documentType: DocumentType
      ): Promise<Factura[]> => {
  const collectionRef = collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/movimientos`);
  const q = query(collectionRef, 
//    where("estado", "==", estado),
    where("tipo", "==",  documentType)
    );

  const docs = await getDocs(q);

  return docs.docs.map(doc => processDocument(doc as DocumentSnapshot));
};