import { GridColDef } from "@mui/x-data-grid";

export const categoryColumns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
        field: "nombre",
        headerName: "Nombre",
        flex: 1,
        cellClassName: "name-column--cell",
    },
];
