import { collection, getDocs } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { Item } from '..';

export const loadItems = async (): Promise<Item[]> => {
  if (!process.env.REACT_APP_CLIENTE_ID) throw new Error('El UID del cliente no existe');

  const collectionRef = collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/items`);
  const docs = await getDocs(collectionRef);

  const items: Item[] = [];
  docs.forEach((doc) => {
    const attributeMap = doc.data().attributeMap ? 
                      doc.data().attributeMap : 
                      doc.data().atributosVariables ? 
                      doc.data().atributosVariables : 
                      {};
    
    items.push({
      id: doc.id,
      referencia: doc.data().referencia,
      nombre: doc.data().nombre,
      categoria: doc.data().categoria,
      imagenurl: doc.data().imagenurl,
      valorunitario: doc.data().valorunitario, 
      precioventamayorista: doc.data().precioventamayorista,
      precioventaminimo: doc.data().precioventaminimo,
      precioventadistribuidor: doc.data().precioventadistribuidor,
      attributeMap: attributeMap,
      visible: true,
      selected: false 
    });
  });

  return items;
};
