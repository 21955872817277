import React, { useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, TextField } from '@mui/material';
import { Deliveryinformation } from '../domain/factura.interface';
import { useFacturaStore } from '../../store/useFacturaStore';
import { UseActualizarFactura } from '../application/UseUpdateFactura';


export const DeliveryDocument: React.FC = () => {
  const { setFactura, factura } = useFacturaStore(state => state);

  const [item, setItem] = useState<Deliveryinformation>({
    street: factura.deliveryinformation?.street,
    number: factura.deliveryinformation?.number || '',
    apartment: factura.deliveryinformation?.apartment || '',
    postalcode: factura.deliveryinformation?.postalcode || '',
    neighborhood: factura.deliveryinformation?.neighborhood || '',
    city: factura.deliveryinformation?.city || '',
  });

  const deliveryinformation = factura.deliveryinformation || {};

  const handleSave = (): void => {
    setFactura({ ...factura, deliveryinformation: item });
    UseActualizarFactura(factura);
  };

  useEffect(() => {
    setItem({
      street: deliveryinformation.street,
      number: deliveryinformation.number || '',
      apartment: deliveryinformation.apartment || '',
      postalcode: deliveryinformation.postalcode || '',
      neighborhood: deliveryinformation.neighborhood || '',
      city: deliveryinformation.city || '',  
    });
  }, [factura]);

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Typography>Datos de la entrega</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box >
            <TextField
              label="Calle"
              fullWidth
              margin="normal"
              value={item.street}
              onChange={(e) => setItem({ ...item, street: e.target.value })}
            />
            <TextField
              label="Numero"
              fullWidth
              margin="normal"
              value={item.number}
              onChange={(e) => setItem({ ...item, number: e.target.value })}
            />
            <TextField
              label="Piso"
              fullWidth
              margin="normal"
              value={item.apartment}
              onChange={(e) => setItem({ ...item, apartment: e.target.value })}
            />

            <TextField
              label="Barrio"
              fullWidth
              margin="normal"
              value={item.neighborhood}
              onChange={(e) => setItem({ ...item, neighborhood: e.target.value })}
            />

            <TextField
              label="Ciudad"
              fullWidth
              margin="normal"
              value={item.city}
              onChange={(e) => setItem({...item, city: e.target.value })}
            />

            <Box sx={{ display: "flex", justifyContent: "center" }}>

                <Button onClick={handleSave}>
                  Guardar
                </Button>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
