import React from 'react';
import { Page, Document, StyleSheet, View } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle';
import InvoiceClientTo from './InvoiceClientTo';
import InvoiceNo from './InvoiceNo';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoiceThankYouMsg from './InvoiceThankYouMsg';
import { Factura } from '../../domain/factura.interface';
import InvoiceCompany from './InvoiceCompany';
import InvoiceLogo from './InvoiceLogo';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft: 60,
        paddingRight: 60,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    headerSection: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    logoCompanySection: {
        flexDirection: 'column',
        width: '30%',
    },
    titleNoClientSection: {
        flexDirection: 'column',
        width: '70%',
    },
});

interface InvoiceProps {
    factura: Factura;
}

const Invoice: React.FC<InvoiceProps> = ({ factura }) => (
    <Document>
        <Page size="LETTER" 
              orientation="landscape" 
              style={styles.page}>
        <View style={styles.headerSection}>
                <View style={styles.logoCompanySection}>
                    <InvoiceLogo />
                    <InvoiceCompany />
                </View>
                <View style={styles.titleNoClientSection}>
                    <InvoiceTitle title='Remisión' />
                    <InvoiceNo factura={factura} />
                    <InvoiceClientTo factura={factura} />
                </View>
            </View>
            
            <InvoiceItemsTable factura={ factura } />
            <InvoiceThankYouMsg />
        </Page>
    </Document>
);

export default Invoice;
