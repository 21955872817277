import React, { useEffect, FC, useState } from 'react';
import { Box } from '@mui/material';
import { InventarioLayout } from '../components';
import { NothingSelectedView, DocumentPendingList } from '../views';

import { DocumentRegisterComponent } from '../components/DocumentRegisterComponent'
import NewDocumentButtonComponent from '../components/NewDocumentButtonComponent';
import { UseSearchFacturasByState } from '../application/UseSearchFacturasByState';
import { useFacturaStore } from '../../store/useFacturaStore';

import { useCategoryStore } from '../../store/useCategoryStore';
import { UseConsultarCategorias } from '../../category';
import { UseConsultarItems } from '../../items/application/UseConsultarItems';
import { DocumentType, Estado } from '../../document/domain/factura.interface';
import { UseConsultarAdvisers } from '../../advisers/application/UseConsultarAdvisers';
import { Trm } from '../../trm';
import { ConsultarTrm } from '../../trm/application/consultarTrm';

type InventoryPageProps = {
  documenttype: DocumentType;
};
 
export const DocumentRegisterPage: FC<InventoryPageProps> = ({ documenttype }) => {
  const {factura, 
      setFacturaspendientes, 
      facturaspendientes,
      setItems,
    setInitFactura, 
    setAdvisers,
    setTrm
  }  = useFacturaStore(state => state);
  const {setCategories }  = useCategoryStore(state => state);

  const fetchFacturasPendientes = async () => {
    try {
      const resp = await UseSearchFacturasByState(Estado.ENPROCESO);
      setFacturaspendientes(resp);
    } catch (error) {
      alert('Error al consultar las facturas pendientes' + error);
    }
  };

  const fetchCategories = async () => {
    try {
      const resp = await UseConsultarCategorias();
      setCategories(resp);
    } catch (error) {
      alert('Error al consultar las categorias' + error);
    }
  };

  const fetchItems = async () => {
    try {
      const resp = await UseConsultarItems();
      setItems(resp);
    } catch (error) {
      alert('Error al consultar las categorias' + error);
    }
  };

  const fetchAdvisers = async () => {
    try {
      const resp = await UseConsultarAdvisers();
      setAdvisers(resp);
    } catch (error) {
      alert('Error al consultar los asesores' + error);
    }
  };

  useEffect(() => {
    fetchFacturasPendientes();
    fetchCategories();
    fetchItems();
    fetchAdvisers();
  }, []);

  useEffect(() => {
    setInitFactura();
  }, [documenttype]);

  const trmQuery = ConsultarTrm();

  useEffect(() => {
    if (!trmQuery.isLoading && trmQuery.data) {
      setTrm(trmQuery.data.value?.toString() ?? '0')
    }
  }, [trmQuery.isLoading, trmQuery.data]);

  return (
    <InventarioLayout>
      {!!factura.id ? (
        <DocumentRegisterComponent />
      ) : !!facturaspendientes ? (
        <NothingSelectedView mensaje={`Selecciona o registra un documento de tipo ${documenttype}`} />
      ) : (
        <NothingSelectedView mensaje={'Cree una factura'} />
      )}
      <Box
        width="100%"
        position="absolute"
        display="flex"
        justifyContent="flex-end"
        bottom={0}
        right={0}
        alignItems="flex-end"
        zIndex={9999}
      >
        <NewDocumentButtonComponent documentType={ documenttype } />

        <DocumentPendingList />
      </Box>
    </InventarioLayout>
  );
};
