import React, { useEffect, useState, Fragment } from 'react';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';

import { PDFViewer } from '@react-pdf/renderer';
import { Factura } from '../../domain/factura.interface';
import Invoice from './invoice'

interface ItemModalProps {
  open: boolean;
  factura: Factura;
  onClose: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'scroll',
  height: '80%',
  p: 4,
};

export const InvoiceModal: React.FC<ItemModalProps> = ({
  open,
  factura,
  onClose
}: ItemModalProps): JSX.Element => {


  const handleCancel = (): void => {
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <h2 id="modal-title">Factura {factura.id}</h2>

        <Fragment>
            <PDFViewer width="1000" height="600" className="app" >
                <Invoice factura={factura}/>
            </PDFViewer>
        </Fragment>
      </Box>
    </Modal>
  );
};
