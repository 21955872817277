import { doc, setDoc, DocumentData } from 'firebase/firestore/lite';
import { FirebaseDB } from '../firebase/config';

export const updateDocument = async (collectionPath: string,
  data: DocumentData,
  id: string) => {
  if (!process.env.REACT_APP_CLIENTE_ID) throw new Error('El UID del cliente no existe');

  const docRef = doc(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/${collectionPath}/${id}`);
  try {
    await setDoc(docRef, data, { merge: true });

  } catch (error) {
    throw new Error('Error al actualizar el documento: ' + error);
  }
};
