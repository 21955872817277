import { collection, doc, setDoc, updateDoc } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { Tercero } from '..';

export const updateTerceroFirebase = async (tercero: Tercero) => {
    try {
        let terceroDocRef;

        const terceroData = {
            tipo: tercero.tipo,
            DNI: tercero.DNI,
            nombre: tercero.nombre,
            apellido: tercero.apellido,
            codigo: tercero.codigo,
            direccion: tercero.direccion,
            ciudad: tercero.ciudad,
            pais: tercero.pais,
            telefono: tercero.telefono,
            email: tercero.email
        };

        if (tercero.id) {
            terceroDocRef = doc(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/terceros`, tercero.id);
            await updateDoc(terceroDocRef, terceroData);
        } else {
            terceroDocRef = doc(collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/terceros`));
            await setDoc(terceroDocRef, terceroData);
            tercero.id = terceroDocRef.id;
        }

    } catch (error) {
        console.error(error);
        alert(error);
    }
}
