import { makeStyles } from "@mui/styles";

export interface ModalStyles {
  modal: {
    width: string;
    height: string;
    display: string;
    alignItems: string;
    justifyContent: string;
    backgroundColor: string;
    margin: string;
    overflowY: string;
  };
  paper: {
    minWidth: number;
    maxWidth: number;
  };
  form: {
    display: string;
    flexDirection: string;
    gap: string;
  };
}

// const useModalStyles = makeStyles<Theme, ModalStyles>((theme) => ({


export const useModalStyles = makeStyles(( props: ModalStyles ) => ( {
  modal: (props: ModalStyles) => ({
    width: props.modal.width,
    height: props.modal.height,  
    display: props.modal.display,
    alignItems: props.modal.alignItems,
    justifyContent: props.modal.justifyContent,
    backgroundColor: props.modal.backgroundColor,
    margin: props.modal.margin,
    overflowY: 'auto',
  }),
  paper: {
    //    backgroundColor: theme.palette.background.paper,
    //    boxShadow: theme.shadows[5],
    //    padding: theme.spacing(2, 4, 3),
    minWidth: 300,
    maxWidth: 500,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
}));

