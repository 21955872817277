import React, { useEffect, useState } from 'react';
import { useTheme } from "@mui/material";
import { tokens } from "../../theme/tokens";
import { Header } from "../../components";
import { Box, TextField } from "@mui/material";
import { ConsultarTrm } from '../application/consultarTrm';
import { LoadingComponent } from '../../ui/components';
import { actualizarTrm } from '../application/actualizarTrm';
import { Trm } from '../domain/trm.interface';
import { useFacturaStore } from '../../store/useFacturaStore';

export const TrmPage = (): JSX.Element => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setTrm } = useFacturaStore(state => state);

  const [trmValue, setTrmValue] = useState<Trm>({ id: '', value: '' });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (newValue.length <= 10 && /^[0-9]*$/.test(newValue)) {
      setTrmValue((prevValue) => ({ ...prevValue, value: newValue }));
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      actualizarTrm(trmValue)
      .then((success) => {
        if (success) {
          setTrm(trmValue.value);
          alert("La TRM se actualizó correctamente.");
        } else {
          alert("Hubo un error al actualizar la TRM.");
        }
      })
      .catch((error) => {
        console.error("Hubo un error al actualizar la TRM:", error);
      });
    }
  };

  const trmQuery = ConsultarTrm();

  useEffect(() => {
    if (!trmQuery.isLoading && trmQuery.data) {
      setTrmValue({
        id: trmQuery.data.id ?? '',
        value: trmQuery.data.value?.toString() ?? '0'
      });
    }
  }, [trmQuery.isLoading, trmQuery.data]);

  if (trmQuery.isLoading) {
    return <LoadingComponent />;
  }

  return (
    <Box m="20px">
      <Header title="TRM" subtitle="" />
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiTextField-root": {
            backgroundColor: colors.primary[400],
            borderRadius: '4px',
            input: {
              color: colors.grey[100],
            },
            label: {
              color: colors.grey[100],
            },
          },
        }}
      >
        <TextField
          label="Ingrese el valor de la TRM"
          variant="outlined"
          fullWidth
          value={trmValue.value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          inputProps={{
            maxLength: 10, // Límite de 10 caracteres
          }}
        />
      </Box>
    </Box>
  );
};
