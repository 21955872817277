import { collection, query, where, getDocs, DocumentSnapshot } from 'firebase/firestore/lite';
import { FirebaseDB } from '../../firebase/config';
import { processDocument } from './processDocument';
import { Estado, Factura } from '../domain/factura.interface';

export const loadInvoicesByState = async (estado: Estado): Promise<Factura[]> => {
  const collectionRef = collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/inventario/movimientos`);
  const q = query(collectionRef, where("estado", "==", estado));
  const docs = await getDocs(q);

  return docs.docs.map(doc => processDocument(doc as DocumentSnapshot));
};