import React from 'react';
import { Button, useTheme } from '@mui/material';
import { tokens } from "../../theme/tokens";
import { useFacturaStore } from '../../store/useFacturaStore';
import { UseActualizarFactura } from '../application/UseUpdateFactura';
import { Estado } from '../domain/factura.interface';

type SaveButtonProps = {
  onCancelar: () => void;
  textToRegister: string;
};


const CancelInvoiceButton: React.FC<SaveButtonProps> = ({ onCancelar, textToRegister}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setFactura, factura } = useFacturaStore(state => state);


  const cancel = (): void => {
    setFactura({ ...factura, estado: Estado.CANCELADA });
    UseActualizarFactura(factura);
    onCancelar();
  };


  return (
    <Button
      onClick={cancel}
      variant='contained' 
      sx={{ backgroundColor: colors.redAccent[700] }}
    >
      {textToRegister} 
    </Button>
  );
};

export default CancelInvoiceButton;
