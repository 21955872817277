import React, { useState } from 'react';

import { Button, useTheme, Box, ListItemText, Typography, IconButton } from '@mui/material';
import { ImageGallery } from './ImageGallery';
import { tokens } from '../../theme/tokens';
import { useFacturaStore } from '../../store/useFacturaStore';
import { AddItemFactura } from '../application/useAddItemFactura';
import { Item } from '../../items/domain/item.interface';
import { EditOutlined, TurnedInNot } from '@mui/icons-material';
import { formatNumber } from '../../helpers/formatNumber';
import { UseUnitPrice } from '../application/useUnitPrice';
import { AttributeSelection } from '../domain/factura.interface';
import { VarianceModal } from './VariancesModal';
import { UpdateItemFirebase } from '../../items/infraestructure/updateItemFirebase';
import { Tercero, TipoTercero } from '../../terceros';

interface ItemInventarioProps {
  item: Item;
}

interface SelectedAttributes {
  [key: string]: { [value: string]: boolean };
}

const obtenerPrecioPorTipoTercero = (tercero: Tercero | undefined, item: Item): number => {
  if (tercero) {
    switch (tercero.tipo) {
      case TipoTercero.CLIENTEMAYORISTA:
        return item.precioventamayorista;
      case TipoTercero.CLIENTEDISTRIBUIDOR:
        return item.precioventadistribuidor;
      default:
        return item.valorunitario;
    }
  } else {
    return item.valorunitario;
  }
};

const ItemNotSelectedComponent: React.FC<ItemInventarioProps> = ({ item }) => {
  const { factura, setFactura, getDocumentTypeByFacturaType } = useFacturaStore(state => state);
  const [selectedAttributes, setSelectedAttributes] = useState<SelectedAttributes>({});
  const [modalOpen, setModalOpen] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const precio = obtenerPrecioPorTipoTercero(factura.tercero, item);

  const onAdditem = () => {

    const selectedAttributesList: AttributeSelection[] = Object.entries(selectedAttributes).reduce<AttributeSelection[]>((acc, [key, values]) => {
      Object.entries(values).forEach(([value, isSelected]) => {
        if (isSelected) {
          acc.push({ key, value });
        }
      });

      return acc;
    }, []);

    const facturaUpdated = AddItemFactura(factura, item, 1, selectedAttributesList);
    setFactura(facturaUpdated)
  }

  const handleSelect = (key: string, value: string) => {
    setSelectedAttributes(prevState => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [value]: !(prevState[key] && prevState[key][value])
      }
    }));
  };

  return (
    <>
      {
        getDocumentTypeByFacturaType()?.itemAlign === 'HORIZONTAL' ? (
          <Box
            key={item.id}
            sx={{
              border: '1px ' + colors.greenAccent[700] + ' solid',
              width: '550px',
              padding: '2px',
              margin: '2px',
              borderRadius: '15px',
              cursor: 'pointer',
              position: 'relative'
            }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'left',
              '& > *': {
                padding: '2px',
              },
              flexWrap: 'wrap'
            }}>

              {/*               <ImageGallery imagenurl={item.imagenurl} /> */}

              <Box
                component="img"
                src={item.imagenurl}
                sx={{
                  width: 50,
                  height: 'auto', // Mantener la relación de aspecto
                  borderRadius: '10px', // Bordes redondeados
                  transition: 'transform 0.3s ease-in-out', // Transición suave
                  '&:hover': {
                    transform: 'scale(2.2)', // Agrandar imagen al pasar el mouse
                  },
                }}
              />

              <Box>
                <Box
                  onClick={onAdditem}
                  sx={{
                    cursor: 'pointer',
                    padding: '5px',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s, color 0.3s',
                    '&:hover': {
                      backgroundColor: '#f0f0f0',
                      color: '#007bff',
                      textDecoration: 'underline',
                    }
                  }}>
                  <Typography flexGrow={3}>{item.nombre}</Typography>
                  <Typography> {item.categoria} </Typography>

                </Box>

                {getDocumentTypeByFacturaType()?.includevariaces && ( // Verifica si includevariaces es true
                  <IconButton onClick={() => setModalOpen(true)}>
                    <EditOutlined />
                  </IconButton>
                )}

              </Box>
              <Typography> {formatNumber(precio)} </Typography>

              <Box>
                {getDocumentTypeByFacturaType()?.includevariaces && (
                  item.attributeMap ? (
                    <ul style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: 0, listStyleType: 'none' }}>
                      {Object.keys(item.attributeMap).map((key, index) => (
                        <li key={key} style={{ marginBottom: '5px' }}>
                          <span style={{ fontWeight: 'bold' }}>{key}:</span>
                          <ul style={{ display: 'flex', paddingLeft: 0, listStyleType: 'none', margin: '5px 0' }}>
                            {item.attributeMap[key].map((value, index) => (
                              <li key={index} style={{ marginRight: '10px' }}>
                                <label>
                                  <input
                                    type="radio"
                                    name={`radio_${key}`}
                                    checked={selectedAttributes[key]?.[value] || false}
                                    onChange={() => handleSelect(key, value)}
                                  />
                                  {value}
                                </label>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  ) : null
                )}
              </Box>


              {/*
              <Button
                variant='contained'
                sx={{ backgroundColor: colors.greenAccent[700] }}
                disabled={false}
                onClick={onAdditem}
              >
                <Typography sx={{ ml: 1 }}>Add</Typography>
              </Button>
*/
              }
            </Box>
          </Box>
        ) : (
          <Box
            key={item.id}
            sx={{
              border: '1px ' + colors.greenAccent[700] + ' solid',
              width: '150px',
              padding: '10px',
              margin: '5px',
              borderRadius: '15px',
              cursor: 'pointer',
              position: 'relative'
            }}
          >
            <Box sx={{
              position: 'absolute',
              top: 0,
              right: 20,
              backgroundColor: colors.greenAccent[700],
              color: 'white',
              padding: '5px 3px',
              borderRadius: '0px 0px 8px 8px'
            }}
            >
              <TurnedInNot />
            </Box>

            <Box sx={{
              textAlign: 'center'
            }}
            >
              <ImageGallery imagenurl={item.imagenurl} />

              <ListItemText sx={{ my: 0 }} primary={item.nombre} />

              <ListItemText sx={{ my: 0 }} primary={UseUnitPrice(factura, item)} />

              <ListItemText sx={{ my: 0 }} primary={item.categoria} />
              <Button
                variant='contained'
                fullWidth
                sx={{ backgroundColor: colors.greenAccent[700] }}
                disabled={false}
                onClick={onAdditem}
              >
                <Typography sx={{ ml: 1 }}>Add</Typography>
              </Button>
            </Box>
          </Box>


        )
      }

      {modalOpen &&
        <VarianceModal
          open={modalOpen}
          itemToEdit={item}
          onClose={() => setModalOpen(false)}
          onSave={(item: Item) => {
            UpdateItemFirebase(item);
          }}

        />}
    </>
  )
}

export default ItemNotSelectedComponent;
