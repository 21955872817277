import { useQuery } from '@tanstack/react-query';
import { loadAdvisers } from '../infraestructure/loadAdvisers';

export const ConsultarAdviser = ()=> {
  try {
    const query = useQuery(
      { queryKey: ['advisers'], 
        queryFn: loadAdvisers,
        staleTime: 1000 * 60 * 60
      })

    return query;
  } catch (error) {
    console.error(error);
    throw error;
  }
};