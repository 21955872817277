import React from 'react';
import { DataGrid, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { Box, IconButton, useTheme } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { Item } from '..'; // Asegúrate de que esta importación sea correcta
import { tokens } from '../../theme/tokens'; // Asegúrate de que esta importación sea correcta
import { itemColumns } from '..';

interface ItemsDataGridProps {
  items: Item[];
  handleEditButtonClick: (item: Item) => void;
}

export const ItemsDataGrid: React.FC<ItemsDataGridProps> = ({ items, handleEditButtonClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const itemColumns2 = [
    ...itemColumns,
    {
      field: 'attributeMap',
      headerName: 'Attributos',
      width: 200,
      renderCell: (params: GridRenderCellParams<Record<string, string[]>>) => {
        const attributeMap = params.value;
        const displayText = Object.entries(attributeMap || {}).map(([key, values]) => `${key}: ${values.join(', ')}`).join('; ');
        return <span>{displayText}</span>;
      }
    },
    {
      field: 'edit',
      headerName: 'Editar',
      width: 100,
      renderCell: (params: any) => (
        <IconButton type="button" sx={{ p: 1 }}
        onClick={() => handleEditButtonClick(params.row)}
        >
        <EditOutlined />
      </IconButton>
      ),
    },
  ];


  return (
    <Box
        m="0 0 0 0"
        height="65vh"
        // custom css for material ui
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          // 🟧🟧🟧 data filter tool-bar present here 🟧🟧🟧
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          "& .css-prh5uq": {
            margin: "0px !important",
          },
        }}
      >
      <DataGrid
        rows={items}
        columns={itemColumns2}
        components={{ Toolbar: GridToolbar }}
      />

    </Box>

  );
};
