import { create } from 'zustand'

import { Item } from '../items';
import { DocumentType, Estado, Estadoorden, Factura } from '../document/domain/factura.interface';
import { DocumentParameter, documentParameters } from '../helpers/documentParameters';
import { Adviser } from '../advisers/domain/adviser.interface';

type State = {
    factura: Factura;
    facturaspendientes: Factura[];
    items: Item[];
    categoryNameSelected: string
    itemNameFilter: string;
    advisers: Adviser[];
    setInitFactura: () => void;
    trm: string;
}

type Actions = {
    setFactura: (newFactura: Factura) => void;
    setItems: (items: Item[]) => void;
    setFacturaspendientes: (facturas: Factura[]) => void;
    getItemsNotInFactura: () => Item[];
    getItemsWithFilter: () => Item[];
    updateFacturaPendiente: (factura: Factura) => void;
    getDocumentTypeByFacturaType: () => DocumentParameter | undefined ;
    getDocumentType: (documentType: DocumentType) => DocumentParameter | undefined ;
    setCategoryNameSelected: (category: string) => void;
    setItemNameFilter: (itemName: string) => void;
    setAdvisers: (advisers: Adviser[]) => void;
    clearStore: () => void;
    deleteFacturapendiente: (id: string) => void;
    setTrm: (trmValue: string) => void;
}

export const useFacturaStore = create<State & Actions>((set, get) => ({
    factura: {
            tipo: DocumentType.VENTAMOSTRADOR,
            fecha: new Date().getTime(),
            consecutivo: 0,
            usuarioid: '',
            estado: Estado.ENPROCESO,
            estadoorden: Estadoorden.PENDIENTE,
            detalles: [],
            total: 0,
            totaldolar: 0,
            color: '',
            idbodega: '',
            nombrebodega: '',
            weight: 0
    },
    facturaspendientes: [],
    items: [],
    categoryNameSelected: '',
    itemNameFilter: '',
    advisers: [],
    trm: '',

    setFactura: (newfactura: Factura) => set(state => ({
        factura: newfactura
    })),
    setInitFactura: () => set(state => ({
        factura: {
            tipo: DocumentType.VENTAMOSTRADOR,
            fecha: new Date().getTime(),
            consecutivo: 0,
            usuarioid: '',
            estado: Estado.ENPROCESO,
            estadoorden: Estadoorden.PENDIENTE,
            detalles: [],
            total: 0,
            totaldolar: 0,
            color: '',
            idbodega: '',
            nombrebodega: '',
            weight: 0
    } 
    })),
    setFacturaspendientes: (facturas: Factura[]) => set(state => ({
        facturaspendientes: facturas
    })),
    
    setItems: (items: Item[]) => set(state => ({
        items: items
    })),
    getItemsNotInFactura: () => {
        const detalleIds = get().factura.detalles.map(detalle => detalle.iditem);
        let items = get().items.filter(item => !detalleIds.includes(item.id));

        if (get().categoryNameSelected) {
            items = items.filter(item => item.categoria === get().categoryNameSelected);
        }

        if (get().itemNameFilter && get().itemNameFilter.length > 2) {
            const filter = get().itemNameFilter.toLowerCase();
            items = items.filter(item => item.nombre.toLowerCase().includes(filter));
        }
        
        return items;
    },

    getItemsWithFilter: () => {
        let items = get().items;
    
        if (get().categoryNameSelected) {
            items = items.filter(item => item.categoria === get().categoryNameSelected);
        }
    
        if (get().itemNameFilter && get().itemNameFilter.length > 2) {
            const filterParts = get().itemNameFilter.toLowerCase().split(' ');
            items = items.filter(item => {
                const itemName = item.nombre.toLowerCase();
                return filterParts.every(part => itemName.includes(part));
            });
        }
        
        return items;
    },
    

    updateFacturaPendiente: (factura: Factura) => {
        const facturaIndex = get().facturaspendientes.findIndex(f => f.id === factura.id);
        if (facturaIndex !== -1) {
            const facturaspendientes = [...get().facturaspendientes];
            facturaspendientes[facturaIndex] = factura;
            set({ facturaspendientes });
        } else {
            set(state => ({ facturaspendientes: [...state.facturaspendientes, factura] }));
        }
    },

    getDocumentTypeByFacturaType: () => {
        const type = documentParameters.find(docType => docType.type === get().factura.tipo);
        if (!type) {
            console.log('Sin tipo')
        }
        return type;
    },

    getDocumentType: (documentType: DocumentType) => {
        return documentParameters.find(docType => docType.type === documentType);
    },

    setCategoryNameSelected: (category: string) => set(state => ({ categoryNameSelected: category })),
    setItemNameFilter: (itemName: string) => set(state => ({ itemNameFilter: itemName })),

    setAdvisers: (advisers: Adviser[]) => set(state => ({
        advisers: advisers
    })),

    clearStore: () => {
        set({}, true)
    },

    deleteFacturapendiente(id: string): void {
        const index = get().facturaspendientes.findIndex(factura => factura.id === id);
        if (index !== -1) {
            const facturaspendientes = [...get().facturaspendientes];
            facturaspendientes.splice(index, 1);
            set({ facturaspendientes });
        } else {
            console.log(`No se encontró ninguna factura con ID ${id}.`);
        }
    },
    setTrm: (trm: string) => set(state => ({
        trm: trm
    })),
}))
