import React, { useState, useEffect } from 'react';
import { Box, Button, ButtonGroup, FormControl, TextField, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import { ConsultarVariances } from '../../varianzas/application/consultarVariances';

interface AttributeMapEditorProps {
  isNew?: boolean;
  attributeKey?: string;
  attributeValues?: string[];
  onAttributeChange: (attributeKey: string, attributeValues: string[]) => void;
}

export const AttributeMapEditor: React.FC<AttributeMapEditorProps> = ({ isNew, attributeKey, attributeValues, onAttributeChange }) => {
  const [key, setKey] = useState(attributeKey || '');
  const [values, setValues] = useState(attributeValues?.join(', ') || '');

  const variancesQuery = ConsultarVariances();

  useEffect(() => {
    if (attributeKey) {
      setKey(attributeKey);
    }
    if (attributeValues) {
      setValues(attributeValues.join(', '));
    }
  }, [attributeKey, attributeValues]);

  const handleKeyChange = (e: SelectChangeEvent<string>) => {
    const newKey = e.target.value;
    setKey(newKey);

    if (!isNew) {
      const updatedValues = values.split(',').map(value => value.trim());
      onAttributeChange(newKey, updatedValues);
    }
  };

  const handleValuesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValues = e.target.value;
    setValues(newValues);

    const updatedValues = newValues.split(',').map(value => value.trim());
    onAttributeChange(key, updatedValues);
  };

  return (
    <Box m="20px">
      <Box>
        {isNew && (
          <FormControl fullWidth variant="outlined">
            <Select
              label="Llave de la varianza"
              value={key}
              onChange={handleKeyChange}
            >
              {variancesQuery.data?.map(variance => (
                <MenuItem key={variance.id} value={variance.name}>{variance.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <TextField
          label={isNew ? "Valor" : key}
          value={values}
          onChange={handleValuesChange}
          variant="outlined"
          fullWidth
        />
      </Box>
    </Box>
   );
};
