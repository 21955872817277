import { GridColDef } from "@mui/x-data-grid";

export const varianzaColumns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
        field: "name",
        headerName: "Nombre",
        flex: 1,
        cellClassName: "name-column--cell",
    },
    {
        field: "value",
        headerName: "Valor",
        flex: 1,
        cellClassName: "name-column--cell",
    },
];
