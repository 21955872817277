import React from 'react';

import { Box, Typography } from '@mui/material';
import { FacturaCategories } from '../../../category';

import { useFacturaStore } from '../../../store/useFacturaStore';
import ItemNotSelectedComponent from '../ItemSelectedDocumentComponent';
import { ItemFilterComponent } from './ItemFilterComponent';

import '../../../../src/css/elements/molecules/_form.css'

export const ItemListNotSelectedComponent: React.FC = () => {

  const { getItemsNotInFactura, 
          getItemsWithFilter,
          getDocumentTypeByFacturaType} = useFacturaStore(state => state);

  return (
    <>
      <Typography variant="h4" sx={{ px: 4, pt: 2 }}>Items inventario  ({ getDocumentTypeByFacturaType()?.includevariaces ? 'Con ' : 'Sin '} varianzas) </Typography>

      <ItemFilterComponent />

      <FacturaCategories />

      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        sx={{
          maxHeight: '400px',
          overflowY: 'auto',
          width: '100%',
          padding: '10px',
          margin: '10px 0',
        }}
      >
        {
          getDocumentTypeByFacturaType()?.includevariaces ? (
            getItemsWithFilter().map(item => (
              item.visible && !item.selected ?
                <ItemNotSelectedComponent key={item.id} item={item} />
                : null
            ))
          ) : (
            getItemsNotInFactura().length > 0 ?
              getItemsNotInFactura().map(item => (
                item.visible && !item.selected ?
                  <ItemNotSelectedComponent key={item.id} item={item} />
                  : null
              ))
              :
              <Box sx={{ margin: '20px 0px' }}>
                <Typography fontSize={20} fontWeight='light' >No hay items disponibles</Typography>
              </Box>
          )
        }
      </Box>
    </>)
}
