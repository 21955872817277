import { Item } from '../domain/item.interface';
import { Itembalance } from '../domain/itembalance.interface';
import { loadItems } from '../infraestructure/loadItems';

export const UseConsultarItemsBalance = async (): Promise<Itembalance[]> => {
  const items: Item[] = await loadItems();
  const itemBalances: Itembalance[] = [];

  items.forEach(item => {
    const combinations = generateCombinations(item.attributeMap);

    const itemBalance: Itembalance = {
      id: item.id,
      referencia: item.referencia,
      nombre: item.nombre,
      attributes: combinations,
    }
    itemBalances.push(itemBalance);
  });

  return itemBalances;
};

// Función para generar todas las combinaciones del attributeMap
function generateCombinations(attributeMap: Record<string, string[]>): { [key: string]: string }[] {
  const attributes = Object.keys(attributeMap);
  const combinations: { [key: string]: string }[] = [{}];

  attributes.forEach(attribute => {
    const values = attributeMap[attribute];
    const newCombinations: { [key: string]: string }[] = [];

    combinations.forEach(combination => {
      values.forEach(value => {
        const newCombination = { ...combination, [attribute]: value };
        newCombinations.push(newCombination);
      });
    });

    combinations.length = 0;
    combinations.push(...newCombinations);
  });

  return combinations;
}
