import { collection, getDocs, DocumentData, QuerySnapshot } from 'firebase/firestore/lite';
import { FirebaseDB } from '../firebase/config';

export const fetchDocuments = async <T>(
  collectionPath: string,
  transform: (doc: { id: string, data: DocumentData }) => T
): Promise<T[]> => {
  if (!process.env.REACT_APP_CLIENTE_ID) {
    throw new Error('El UID del cliente no existe');
  }

  const collectionRef = collection(FirebaseDB, `${process.env.REACT_APP_CLIENTE_ID}/${collectionPath}`);
  const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(collectionRef);

  // Utilizando map para transformar directamente los documentos
  const result: T[] = querySnapshot.docs.map((doc) => transform({ id: doc.id, data: doc.data() }));

  return result;
};
