import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { localizer, getMessagesES } from '../../helpers';
import { useCalendarStore } from '../../hooks';

import { consultarFacturasPagadas } from '../';
import { CalendarEvent, CalendarModal } from '..';
import {  } from '..';
export const VentasdiariasPage = () => {
  const dispatch = useDispatch();
  const { events, setActiveEvent } = useCalendarStore();

  const [open, setOpen] = useState(false)

  const [ lastView, setLastView ] = useState(localStorage.getItem('lastView') || 'week' );

  const eventStyleGetter = ( event, start, end, isSelected ) => {

    const style = {
      backgroundColor: '#347CF7',
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white'
    }

    return {
      style
    }
  }

  useEffect(() => {
    dispatch( consultarFacturasPagadas() );
  }, []);

  const onDoubleClick = ( event ) => {
    setOpen(true)
  }

  const onSelect = ( event ) => {
    setActiveEvent( event );
  }

  const onViewChanged = ( event ) => {
    localStorage.setItem('lastView', event );
    setLastView( event )
  }

  return (
    <>
    <Calendar
      culture="es"
      localizer={localizer}
      startAccessor="start"
      endAccessor="end"
      defaultView={ lastView }
      events={ events }
      style={{ height: 'calc( 100vh - 80px )' }}
      min={new Date(2022, 10, 0, 8, 0, 0)}
      max={new Date(2024, 10, 0, 22, 0, 0)} 
      messages={ getMessagesES() }
      components={{
        event: CalendarEvent
      }}
      onDoubleClickEvent={ onDoubleClick }
      onSelectEvent={ onSelect }
      onView={ onViewChanged }
      eventPropGetter={ eventStyleGetter }
      views={['month', 'week']}
    />
      <CalendarModal open={open} setOpen={setOpen}/>
    </>
  )
}
