import React, { useEffect, useState } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Checkbox, FormControl, FormControlLabel, MenuItem, Select, TextField } from '@mui/material';
import { Tercero, TipoTercero } from '../../../terceros';
import { useFacturaStore } from '../../../store/useFacturaStore';
import { UseActualizarFactura } from '../../application/UseUpdateFactura';
import { getDate } from '../../../helpers/getDate';

interface TerceroDocumentProps {

}

export const TerceroDocument: React.FC<TerceroDocumentProps> = () => {
  const { setFactura, factura, trm } = useFacturaStore(state => state);

  const [facturaEnDolares, setFacturaEnDolares] = useState(false);
  const [valorDolar, setValorDolar] = useState('');
  const [weight, setWeight] = useState('');

  const [item, setItem] = useState<Tercero>({
    tipo: TipoTercero.CLIENTEDISTRIBUIDOR,
    DNI: factura.tercero?.DNI || '',
    nombre: factura.tercero?.nombre || '',
    apellido: '',
    codigo: factura.tercero?.codigo || '',
    direccion: factura.tercero?.direccion || '',
    ciudad: factura.tercero?.ciudad || '',
    pais: factura.tercero?.pais || '',
    telefono: factura.tercero?.telefono || '',
    email: factura.tercero?.email || '',
  });

  const handleSave = (): void => {
    setFactura({ ...factura, 
                facturaendolares: facturaEnDolares,
                valordolar: Number(valorDolar),
                weight: Number(weight),
                tercero: item });
     UseActualizarFactura(factura);
  };

  useEffect(() => {
    setItem({
      tipo: factura.tercero?.tipo ||TipoTercero.CLIENTEDISTRIBUIDOR,
      DNI: factura.tercero?.DNI || '',
      nombre: factura.tercero?.nombre || '',
      apellido: '',
      codigo: factura.tercero?.codigo || '',      
      direccion: factura.tercero?.direccion || '',
      ciudad: factura.tercero?.ciudad || '',
      pais: factura.tercero?.pais || '',
      telefono: factura.tercero?.telefono || '',
      email: factura.tercero?.email || '',
    });

    setFacturaEnDolares(factura.facturaendolares || false);
    setValorDolar(factura.valordolar ? factura.valordolar.toString() : '');
    setWeight(factura.weight ? factura.weight.toString() : '');

  }, [factura]);

  useEffect(() => {

    if (facturaEnDolares && valorDolar === '') {
      setValorDolar(trm);
    }
  }, [facturaEnDolares]);


  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Datos del cliente {item.tipo}</Typography> - Fecha { getDate(factura.fecha) }
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              overflow: 'auto', // Permite el desplazamiento
              maxHeight: '300px', // Establece una altura máxima para el área de contenido
            }}
          >
            <Select
              labelId="tipo-label"
              id="tipo"
              value={item.tipo}
              label="Tipo"
              onChange={(e) => setItem({ ...item, tipo: e.target.value as TipoTercero })}
            >
              <MenuItem value={'CLIENTEDISTRIBUIDOR'}>Cliente minorista</MenuItem>
              <MenuItem value={'CLIENTEMAYORISTA'}>Cliente mayorista</MenuItem>
              <MenuItem value={'PROVEEDOR'}>Proveedor</MenuItem>
              <MenuItem value={'CLIENTELIBRE'}>Cliente libre</MenuItem>
            </Select>


              <FormControlLabel
                  control={
                    <Checkbox
                      checked={facturaEnDolares}
                      onChange={(e) => setFacturaEnDolares(e.target.checked)}
                    />
                  }
                  label={`Factura en Dólares: ${ trm }`}
                />
                {facturaEnDolares && (
                  <TextField
                    label="Valor del Dólar"
                    fullWidth
                    margin="normal"
                    value={valorDolar}
                    onChange={(e) => setValorDolar(e.target.value)}
                  />
                )}

            <TextField
              label="Peso KG"
              fullWidth
              margin="normal"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />

            <TextField
              label="Nombre"
              fullWidth
              margin="normal"
              value={item.nombre}
              onChange={(e) => setItem({ ...item, nombre: e.target.value })}
            />

            <TextField
              label="Codigo"
              fullWidth
              margin="normal"
              value={item.codigo}
              onChange={(e) => setItem({ ...item, codigo: e.target.value })}
            />

            <TextField
              label="Ciudad"
              fullWidth
              margin="normal"
              value={item.ciudad}
              onChange={(e) => setItem({ ...item, ciudad: e.target.value })}
            />
            <TextField
              label="Pais"
              fullWidth
              margin="normal"
              value={item.pais}
              onChange={(e) => setItem({ ...item, direccion: e.target.value })}
            />

            <TextField
              label="Direccion"
              fullWidth
              margin="normal"
              value={item.direccion}
              onChange={(e) => setItem({ ...item, direccion: e.target.value })}
            />

            <TextField
              label="Telefono"
              fullWidth
              margin="normal"
              value={item.telefono}
              onChange={(e) =>
                setItem({
                  ...item,
                  telefono: e.target.value,
                })
              }
            />

            <Box sx={{ display: "flex", justifyContent: "center" }}>

              <Button onClick={handleSave}>
                Guardar
              </Button>
            </Box>

          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
